import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Content } from "antd/lib/layout/layout";
import { Button, Space } from "antd";
import Title from "antd/es/typography/Title";
import { EditOutlined, LeftOutlined } from "@ant-design/icons";
import { binDevicePath } from "../../routes";
import useBinDeviceStore from "../../stores/bin-device.store";
import BinDeviceDeleteButton from "../bin-device-page/BinDeviceDeleteButton";
import { BinDevice } from "../../models/devices/bin-device";
import { DeviceType } from "../../models/devices/device-type";
import DeviceFormModal from "../../components/devices/DeviceFormModal";
import TechnicalSection from "../../components/devices/TechnicalSection";
import MqttSection from "../../components/devices/MqttSection";

const BinDeviceDetailPage = () => {
  let { deviceUuid } = useParams();
  const navigate = useNavigate();

  const getBinByUuid = useBinDeviceStore((state) => state.getByUuid);
  const updateBin = useBinDeviceStore((state) => state.update);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    if (deviceUuid) {
      getBinByUuid(deviceUuid!);
    }
  }, [deviceUuid, getBinByUuid]);

  const handleEdit = (bin: BinDevice) => {
    bin.uuid = deviceUuid!;
    bin.data.type = DeviceType.bin;
    updateBin(bin);
    setIsEditModalOpen(false);
  };

  const bin = useBinDeviceStore((state) => state.entities[deviceUuid!]);

  return (
    <Content className="BinDeviceDetailPage">
      <Button shape="circle" icon={<LeftOutlined />} onClick={() => navigate(binDevicePath)} />
      <Space direction={"vertical"} size={"middle"}>
        <Title level={2}>Detalles Papelera: {bin.name}</Title>
        <Space>
          <Button type={"primary"} onClick={() => setIsEditModalOpen(true)}>
            <EditOutlined /> Editar recipiente
          </Button>
          <DeviceFormModal
            open={isEditModalOpen}
            onCancel={() => setIsEditModalOpen(false)}
            handleSubmit={handleEdit}
            device={bin}
            actionText={"Editar recipiente"}
            type={DeviceType.bin}
            isEdit={true}
          />
          <BinDeviceDeleteButton uuid={deviceUuid!} onDelete={() => navigate(binDevicePath)}></BinDeviceDeleteButton>
        </Space>
        {bin ? <TechnicalSection device={bin}></TechnicalSection> : null}
        {bin?.mqttUser ? <MqttSection mqttUser={bin.mqttUser}></MqttSection> : null}
      </Space>
    </Content>
  );
};

export default BinDeviceDetailPage;
