import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../stores/auth.store";
import "./SignInForm.scss";

interface FormValues {
  email: string;
  password: string;
}
const SignInForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<FormValues>();
  const signIn = useAuthStore((state) => state.signIn);
  const onFinish = async (values: FormValues) => {
    console.log(values);
    await signIn(values.email, values.password);
    navigate("/private/operator");
  };

  return (
    <Form<FormValues> form={form} name="login" onFinish={onFinish} className={"SignInForm"} scrollToFirstError>
      <Form.Item
        name="email"
        rules={[
          { type: "email", message: "The input is not valid E-mail!" },
          { required: true, message: "Please input your E-mail!" },
        ]}
      >
        <Input prefix={<MailOutlined className="site-form-item-icon" />} type="email" placeholder="Email" />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: "Please input your Password!" }]}>
        <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" />
      </Form.Item>
      <Button type="primary" htmlType="submit" className="LoginPost" block>
        Log in
      </Button>{" "}
    </Form>
  );
};

export default SignInForm;
