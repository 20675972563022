import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import useScannerStore from "../../stores/scanner.store";
import TechnicalSection from "../../components/devices/TechnicalSection";
import { Content } from "antd/lib/layout/layout";
import { Button, Space } from "antd";
import MqttSection from "../../components/devices/MqttSection";
import { ScannerDevice } from "../../models/devices/scanner-device";
import { DeviceType } from "../../models/devices/device-type";
import ScannerDeleteButton from "../scanner-page/ScannerDeleteButton";
import { scannerDevicePath } from "../../routes";
import { EditOutlined, LeftOutlined } from "@ant-design/icons";
import DeviceFormModal from "../../components/devices/DeviceFormModal";
import Title from "antd/es/typography/Title";

const ScannerDetailPage = () => {
  let { deviceUuid } = useParams();
  const navigate = useNavigate();

  const getScannerByUuid = useScannerStore((state) => state.getByUuid);
  const updateScanner = useScannerStore((state) => state.update);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    if (deviceUuid) {
      getScannerByUuid(deviceUuid!);
    }
  }, [deviceUuid, getScannerByUuid]);

  const handleEdit = (scanner: ScannerDevice) => {
    scanner.uuid = deviceUuid!;
    scanner.data.type = DeviceType.scanner;
    updateScanner(scanner);
    setIsEditModalOpen(false);
  };

  const scanner = useScannerStore((state) => state.entities[deviceUuid!]);

  return (
    <Content className="ScannerDetailPage">
      <Button shape="circle" icon={<LeftOutlined />} onClick={() => navigate(scannerDevicePath)} />
      <Space direction={"vertical"}>
        <Title>Detalles Scanner: {scanner.name}</Title>
        <Space>
          <Button type={"primary"} onClick={() => setIsEditModalOpen(true)}>
            <EditOutlined /> Editar scanner
          </Button>
          <DeviceFormModal
            open={isEditModalOpen}
            onCancel={() => setIsEditModalOpen(false)}
            handleSubmit={handleEdit}
            device={scanner}
            actionText={"Editar scanner"}
            type={DeviceType.scanner}
            isEdit={true}
          />
          <ScannerDeleteButton uuid={deviceUuid!} onDelete={() => navigate(scannerDevicePath)}></ScannerDeleteButton>
        </Space>
        <br />
        {scanner ? <TechnicalSection device={scanner}></TechnicalSection> : null}
        {scanner?.mqttUser ? <MqttSection mqttUser={scanner.mqttUser}></MqttSection> : null}
      </Space>
    </Content>
  );
};

export default ScannerDetailPage;
