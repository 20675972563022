import { Button, Form, Input, InputNumber } from "antd";
import { Bin } from "../models/bin";
import React from "react";

interface BinFormProps {
  bin: Bin | null;
  handleSubmit: any;
  buttonMessage: string;
}

const BinForm = (props: BinFormProps) => {
  return (
    <Form
      name="device-form"
      layout="horizontal"
      initialValues={props.bin ? props.bin : {}}
      onFinish={props.handleSubmit}
      className={"MqttAclForm"}
      scrollToFirstError
    >
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input type="text" />
      </Form.Item>
      <Form.Item name="address" label="Dirección">
        <Input type="text" />
      </Form.Item>
      <Form.Item name="latitude" label="Latitud">
        <InputNumber min={-90} max={90} style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="longitude" label="Longitud">
        <InputNumber min={-180} max={180} style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="deviceUuid" label="UUID de dispositivo" hidden={!!props.bin}>
        <Input type="text" />
      </Form.Item>
      <Button type="primary" htmlType="submit" className="LoginPost" block>
        {props.buttonMessage}
      </Button>
    </Form>
  );
};

export default BinForm;
