import { create } from "zustand";
import { Pack } from "../models/boxes/pack";
import { packApi } from "../api/pack.api";

type PackStoreEntity = { [key: string]: Pack };
interface PackStore {
  entities: PackStoreEntity;
  totalEntities: number;
  create: (qrCode: string, boxes: string[]) => void;
  getAll: (page: number, interval: number) => Promise<Pack[]>;
  delete: (uuid: string) => void;
  get: (uuid: string) => void;
  update: (pack: Pack) => void;
  updateState: (pack: Pack) => void;
}

export const usePackStore = create<PackStore>()((set, get) => ({
  entities: {},
  totalEntities: 0,
  create: async (qrCode, boxes) => {
    const createdPack = await packApi.create(qrCode, boxes);
    set((state) => {
      return {
        entities: { ...state.entities, ...{ [createdPack.uuid]: createdPack } },
        totalEntities: state.totalEntities + 1,
      };
    });
  },
  getAll: async (page: number, interval: number) => {
    const data = await packApi.getByPagination(page, interval);
    const currentPacks = get().entities;
    const packs = data.data;

    packs.forEach((pack) => {
      currentPacks[pack.uuid] = pack;
    });

    set({ entities: currentPacks, totalEntities: data.metadata.totalCount });
    return packs;
  },
  delete: async (uuid: string) => {
    let response = await packApi.delete(uuid);
    if (response) {
      set((state) => {
        delete state.entities[uuid];
        return {
          entities: { ...state.entities },
          totalEntities: state.totalEntities - 1,
        };
      });
    }
  },
  get: async function (uuid: string) {
    const pack = await packApi.get(uuid);
    const currentPacks = get().entities;
    currentPacks[pack.uuid] = pack;
    set({ entities: currentPacks });
  },
  update: async function (pack: Pack) {
    const updatePack = await packApi.update(pack);
    set((state) => {
      return {
        entities: { ...state.entities, ...{ [updatePack.uuid]: updatePack } },
        totalEntities: state.totalEntities + 1,
      };
    });
  },
  updateState: async function (pack: Pack) {
    const updatePack = await packApi.updateState(pack);
    set((state) => {
      return {
        entities: { ...state.entities, ...{ [updatePack.uuid]: updatePack } },
        totalEntities: state.totalEntities + 1,
      };
    });
  },
}));

export const selectPackList = (state: PackStore) => Object.values(state.entities);

export default usePackStore;
