import { Market } from "../models/market/market";
import { create } from "zustand";
import { marketApi } from "../api/market.api";

type MarketStoreEntity = { [key: string]: Market };
interface MarketStore {
  entities: MarketStoreEntity;
  totalEntities: number;
  create: (market: Market) => void;
  delete: (uuid: string) => void;
  getByPagination: (page: number, size: number) => Promise<Market[]>;
  get: (uuid: string) => Promise<Market>;
  update: (market: Market) => void;
}

export const useMarketStore = create<MarketStore>((set, get) => ({
  entities: {},
  totalEntities: 0,
  create: async (market: Market) => {
    const createdMarket = await marketApi.create(market);
    set((state) => {
      return {
        entities: { ...state.entities, ...{ [createdMarket.uuid]: createdMarket } },
        totalEntities: state.totalEntities + 1,
      };
    });
  },
  delete: async (uuid: string) => {
    await marketApi.delete(uuid);
    set((state) => {
      delete state.entities[uuid];
      return {
        entities: { ...state.entities },
        totalEntities: state.totalEntities - 1,
      };
    });
  },
  getByPagination: async (page: number, size: number) => {
    const data = await marketApi.getByPagination(page, size);
    const currentMarkets = get().entities;
    const markets = data.data;

    markets.forEach((market) => {
      currentMarkets[market.uuid] = market;
    });

    set({ entities: currentMarkets, totalEntities: data.metadata.totalCount });
    return markets;
  },
  get: async function (uuid: string): Promise<Market> {
    const market = await marketApi.get(uuid);
    set((state) => {
      return {
        entities: { ...state.entities, ...{ [market.uuid]: market } },
      };
    });
    return market;
  },
  update: async (market: Market) => {
    await marketApi.update(market);
    set((state) => {
      delete state.entities[market.uuid];
      return {
        entities: { ...state.entities, ...{ [market.uuid]: market } },
        totalEntities: state.totalEntities - 1,
      };
    });
  },
}));
