import { Bin } from "../../models/bin";
import React, { useState } from "react";
import { Button, Form, Input, Modal, Space } from "antd";
import { EditOutlined } from "@ant-design/icons";
import useBinsStore from "../../stores/bin.store";

interface BinEditProps {
  bin: Bin;
}

const BinEditDeviceButton = ({ bin }: BinEditProps) => {
  const updateBin = useBinsStore((state) => state.updateDevice);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEdit = async (values: Bin) => {
    values.uuid = bin.uuid;
    updateBin(values);
    setIsModalOpen(false);
  };

  return (
    <>
      <Space>
        <Button onClick={() => setIsModalOpen(true)}>
          <EditOutlined />
        </Button>
        <Modal title={"Cambiar dispositivo"} open={isModalOpen} onCancel={() => setIsModalOpen(true)} footer={null}>
          <Form name="device-form" layout="horizontal" initialValues={bin} onFinish={handleEdit} scrollToFirstError>
            <Form.Item name="deviceUuid" label="Uuid del dispositivo" rules={[{ required: true }]}>
              <Input type="text" />
            </Form.Item>
            <Button type="primary" htmlType="submit" className="LoginPost" block>
              Cambiar dispositivo
            </Button>
          </Form>
        </Modal>
      </Space>
    </>
  );
};

export default BinEditDeviceButton;
