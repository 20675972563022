import { apiV1 } from "./api-v1";
import { Box } from "../models/boxes/box";
import { BoxState } from "../models/boxes/box-state";
import { PaginationResult } from "../models/pagination";

export const boxApi = {
  endpoint: "/boxes",
  async create(): Promise<Box> {
    const response = await apiV1.post(this.endpoint);
    return response.data;
  },
  async getBoxesByPagination(pageIndex: number, pageSize: number): Promise<PaginationResult<Box>> {
    let response = await apiV1.get(`${this.endpoint}?pageIndex=${pageIndex}&pageSize=${pageSize}`);
    return response.data;
  },
  async getSoldBoxesByPagination(pageIndex: number, pageSize: number): Promise<PaginationResult<Box>> {
    let response = await apiV1.get(
      `${this.endpoint}/?pageIndex=${pageIndex}&pageSize=${pageSize}&sold=true&state=in-warehouse`
    );
    return response.data;
  },
  async delete(uuid: string): Promise<Boolean> {
    const response = await apiV1.delete(`boxes/${uuid}`);
    return !!response.data;
  },
  async update(uuid: string, box: Box): Promise<Box> {
    const data = prepareData(box);

    const response = await apiV1.put(`boxes/${uuid}/state`, {
      state: box.state,
      data,
    });
    return response.data;
  },
};

const prepareData = (box: Box) => {
  switch (box.state) {
    case BoxState.InWarehouse:
      return {
        operatorUuid: box.stateData.operatorUuid,
      };
    case BoxState.Packed:
      return {
        operatorUuid: box.stateData.operatorUuid,
        packUuid: box.stateData.packUuid,
      };
    case BoxState.InShop:
      return {
        operatorUuid: box.stateData.operatorUuid,
        packUuid: box.stateData.packUuid,
      };
    case BoxState.InUsage:
      return {
        shopUuid: box.stateData.shopUuid,
        customerUuid: box.stateData.customerUuid,
      };
    case BoxState.Collected:
      return {
        binUuid: box.stateData.binUuid,
        customerUuid: box.stateData.customerUuid,
      };
  }
};
