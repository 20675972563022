import { Flex, Space } from "antd";
import OperatorsTable from "./OperatorsTable";
import { useEffect, useState } from "react";
import useOperatorsStore from "../../stores/operators.store";
import CreateOperatorButton from "./CreateOperatorButton";
import Title from "antd/es/typography/Title";
import { Operator } from "../../models/users/operator";

export const OPERATORS_PAGE_SIZE = 10;

const OperatorPage = () => {
  const getAllOperators = useOperatorsStore((state) => state.getAll);
  const totalOperators = useOperatorsStore((state) => state.totalEntities);

  const [operatorsList, setOperatorsList] = useState<Operator[]>([]);
  const [page, changePage] = useState<number>(0);

  useEffect(() => {
    getAllOperators(page, OPERATORS_PAGE_SIZE).then((operators) => {
      setOperatorsList(operators);
    });
  }, [totalOperators, getAllOperators, page]);

  return (
    <Flex vertical>
      <Title>Operators</Title>
      <Space size={"large"}>
        <CreateOperatorButton />
      </Space>
      <br />
      <br />
      <OperatorsTable
        data={operatorsList}
        pagination={{
          pageSize: OPERATORS_PAGE_SIZE,
          total: totalOperators,
          showSizeChanger: false,
        }}
        onChange={(pagination) => {
          getAllOperators(pagination.current! - 1, OPERATORS_PAGE_SIZE).then((operators) => {
            setOperatorsList(operators);
            changePage(pagination.current! - 1);
          });
        }}
      ></OperatorsTable>
    </Flex>
  );
};

export default OperatorPage;
