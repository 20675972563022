import { Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Market } from "../../models/market/market";
import { useMarketStore } from "../../stores/market.store";
import ModalFormMarketComponent from "../../components/market/MarketFormModal";

interface EditProps {
  market: Market;
}
const EditMarketButton = (props: EditProps) => {
  const updateMarket = useMarketStore((state) => state.update);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onFinish = async (values: Market) => {
    values.uuid = props.market.uuid;
    updateMarket(values);
    setIsModalOpen(false);
  };
  return (
    <div>
      <Button onClick={() => setIsModalOpen(true)}>
        <EditOutlined />
      </Button>
      <ModalFormMarketComponent
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        handleSubmit={onFinish}
        market={props.market}
        isCreate={false}
      />
    </div>
  );
};

export default EditMarketButton;
