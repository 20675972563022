import React from "react";
import { Card } from "antd";
import SignInForm from "./SignInForm";

const SignInPage = () => {
  return (
    <Card title="Login" style={{ width: 400, margin: "100px auto" }}>
      <SignInForm />
    </Card>
  );
};

export default SignInPage;
