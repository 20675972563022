import "./PackagingPage.scss";
import React, { useState } from "react";
import { Typography, Flex, Button } from "antd";
import { usePackStore } from "../../stores/pack.store";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { Content } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { packPath } from "../../routes";

const { Title } = Typography;

const MAX_CONTAINERS = 50;

const scanQrPackText = "1. Escaneja el QR del paquet";
const scanQrContainerText = "2. Escaneja el QR del contenidor";
const packCreatedText = "Paquet creat correctament!";

enum types {
  PACK = "p",
  CONTAINER = "e",
}

const constraints: MediaTrackConstraints = {
  facingMode: "environment",
};

let pack: { qrCode: string; boxes: string[] } = { qrCode: "", boxes: [] };

function checkQR(qr: string, type: types): boolean {
  let qrCompany = qr.split(":")[0];
  let qrType = qr.split(":")[2];

  return qrCompany === "paka" && qrType === type;
}

const PackagingPage = () => {
  const navigate = useNavigate();
  const createPack = usePackStore().create;
  const [instruction, setText] = useState(scanQrPackText);
  const [numContainers, setNumContainers] = useState(0);

  function onClose() {
    restart();
    navigate(packPath);
  }

  function restart() {
    pack = { qrCode: "", boxes: [] };
    setText(scanQrPackText);
    setNumContainers(0);
  }

  function onResult(result: string) {
    if (checkQR(result, types.PACK) && pack.qrCode === "") {
      pack.qrCode = result.split(":")[3];
      setText(scanQrContainerText);
    } else if (pack.qrCode !== "" && checkQR(result, types.CONTAINER)) {
      let uuid = result.split(":")[4];

      if (!pack.boxes.includes(uuid)) {
        pack.boxes.push(uuid);
        setNumContainers((prevState) => prevState + 1);

        if (pack.boxes.length === MAX_CONTAINERS) {
          setText(packCreatedText);
          createPack(pack.qrCode, pack.boxes);
        }
      }
    }
  }

  return (
    <Content className="PackagingPage">
      <Button shape="circle" icon={<LeftOutlined />} onClick={onClose} />
      <Flex className={"flexPagination"} justify={"flex-start"} align={"center"} gap="middle" vertical>
        <Title>Empaquetaments</Title>
        <Title level={4} id="instruction">
          {instruction}
        </Title>
        <div className="video">
          <QrScanner
            onDecode={(result) => onResult(result)}
            onError={(error) => console.log(error?.message)}
            scanDelay={100}
            constraints={constraints}
          />
        </div>
        <Title level={5}>Nombre d'envasos escanejats:</Title>
        <Title level={3} className={"numContainers"}>
          {numContainers}/{MAX_CONTAINERS}
        </Title>
        <Button onClick={restart} type={"primary"} size={"large"}>
          Reiniciar
        </Button>
      </Flex>
    </Content>
  );
};

export default PackagingPage;
