import { Operator } from "../../../models/users/operator";
import { Modal } from "antd";
import OperatorForm from "./OperatorForm";

interface ModalProps {
  open: boolean;
  onCancel: any;
  handleSubmit: any;
  operator: Operator | null;
  isCreate: boolean;
}
const ModalFormOperatorComponent = (props: ModalProps) => {
  const text = props.isCreate ? "Añadir operario" : "Modificar operario";

  return (
    <Modal title={text} open={props.open} onCancel={props.onCancel} footer={null}>
      <OperatorForm operator={props.operator} handleSubmit={props.handleSubmit} buttonMessage={text} />
    </Modal>
  );
};

export default ModalFormOperatorComponent;
