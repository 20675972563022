import React from "react";
import { Card } from "antd";

const SignUpForm = () => {
  return (
    <Card title="Register" style={{ width: 400, margin: "100px auto" }}>
      <SignUpForm />
    </Card>
  );
};

export default SignUpForm;
