import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";
import useShopsStore from "../../stores/shops.store";
import { Shop } from "../../models/users/shop";
import ShopFormModal from "../../components/users/shop/ShopFormModal";

interface EditShopButtonProps {
  shop: Shop;
}
const EditShopButton = ({ shop }: EditShopButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const updateShop = useShopsStore((state) => state.update);

  const handleSubmit = async (values: Shop) => {
    values.uuid = shop.uuid;
    updateShop(values);
    setIsModalOpen(false);
  };

  return (
    <div>
      <Button onClick={() => setIsModalOpen(true)}>
        <EditOutlined />
      </Button>
      <ShopFormModal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        handleSubmit={handleSubmit}
        shop={shop}
        isCreate={false}
      />
    </div>
  );
};
export default EditShopButton;
