import "./PrivatePage.scss";
import { Outlet } from "react-router";
import PrivatePageHeader from "./PrivatePageHeader";
import PrivatePageSideBar from "./PrivatePageSideBar";
import useAuthStore from "../../stores/auth.store";
import { useNavigate } from "react-router-dom";
import { Content } from "antd/es/layout/layout";
import { Flex } from "antd";
import { useEffect } from "react";
import { signInPath } from "../../routes";

const PrivatePage = () => {
  console.log("private");
  const navigate = useNavigate();
  const authToken = useAuthStore((state) => state.authToken);
  useEffect(() => {
    if (!authToken) {
      navigate(signInPath, { replace: true });
    }
  }, [authToken, navigate]);

  return (
    <Content className={"PrivatePage"}>
      <PrivatePageHeader />
      <Flex className={"PrivatePage__content"}>
        <PrivatePageSideBar />
        <div className={"PrivatePage__content__outlet"}>
          <Outlet />
        </div>
      </Flex>
    </Content>
  );
};

export default PrivatePage;
