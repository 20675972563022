import ShopForm from "./ShopForm";
import { Modal } from "antd";
import { Shop } from "../../../models/users/shop";

interface ShopModalProps {
  open: boolean;
  onCancel: any;
  handleSubmit: (values: Shop) => void;
  shop: Shop | null;
  isCreate: boolean;
}

const ShopFormModal = (props: ShopModalProps) => {
  const text = props.isCreate ? "Añadir comercio" : "Modificar comercio";

  return (
    <Modal title={text} open={props.open} onCancel={props.onCancel} footer={null}>
      <ShopForm shop={props.shop} handleSubmit={props.handleSubmit} buttonMessage={text} />
    </Modal>
  );
};

export default ShopFormModal;
