import { ScannerDevice } from "../../models/devices/scanner-device";
import { BinDevice } from "../../models/devices/bin-device";
import { Modal } from "antd";
import DeviceForm from "./DeviceForm";

interface ModalProps {
  open: boolean;
  onCancel: any;
  handleSubmit: any;
  device: ScannerDevice | BinDevice | null;
  actionText: string;
  type: string;
  isEdit: boolean;
}
const ModalFormDeviceComponent = (props: ModalProps) => {
  return (
    <Modal title={props.actionText} open={props.open} onCancel={props.onCancel} footer={null}>
      <DeviceForm
        device={props.device}
        handleSubmit={props.handleSubmit}
        buttonMessage={props.actionText}
        type={props.type}
        isEdit={props.isEdit}
      />
    </Modal>
  );
};

export default ModalFormDeviceComponent;
