import { Button, Modal, Space } from "antd";
import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import React from "react";
import useBoxStore from "../../stores/box.store";

const { confirm } = Modal;

interface BoxDeleteProps {
  uuid: string;
}

const BoxDeleteButton = ({ uuid }: BoxDeleteProps) => {
  const deleteBox = useBoxStore((state) => state.delete);

  const onClick = async () => {
    confirm({
      title: "Seguro que quieres eliminar este envase?",
      icon: <ExclamationCircleFilled />,
      onOk: async function () {
        deleteBox(uuid);
      },
    });
  };

  return (
    <>
      <Space>
        <Button onClick={onClick}>
          <DeleteOutlined />
        </Button>
      </Space>
    </>
  );
};

export default BoxDeleteButton;
