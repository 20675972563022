import { PaginationProps, Space, Switch, Table } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import React from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { ScannerDevice } from "../../models/devices/scanner-device";
import { generateScannerDeviceDetailPath } from "../../routes";
import ScannerDeleteButton from "./ScannerDeleteButton";

const columns: ColumnsType<ScannerDevice> = [
  {
    title: "UUID",
    dataIndex: "uuid",
    key: "uuid",
  },
  {
    title: "Nombre dispositivo",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Funcionamiento MQTT",
    dataIndex: ["mqttUser", "enabled"],
    key: "enabled",
    render: (value, record) => (
      <Switch
        disabled
        checked={value}
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        defaultChecked
      />
    ),
  },
  {
    title: "Identificador del comercio",
    dataIndex: ["data", "shopUuid"],
    key: "shopUuid",
  },
  {
    title: "Fecha creación",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: "Borrar",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <ScannerDeleteButton uuid={record.uuid} />
      </Space>
    ),
    onCell: () => {
      return {
        onClick: (event) => {
          event.stopPropagation();
        },
      };
    },
  },
];

type ScannerDevicesTableProp = {
  data: readonly ScannerDevice[];
  pagination: PaginationProps;
  onChange: (pagination: PaginationProps) => void;
};

const ScannerDevicesTable = (props: ScannerDevicesTableProp) => {
  const navigate = useNavigate();

  const onRow = (record: ScannerDevice, index: number | undefined) => {
    return {
      onClick: () => {
        navigate(generateScannerDeviceDetailPath(record.uuid), { replace: true });
      },
    };
  };
  return (
    <Table
      columns={columns}
      dataSource={props.data}
      rowKey={"uuid"}
      onRow={onRow}
      pagination={props.pagination}
      onChange={props.onChange}
    />
  );
};

export default ScannerDevicesTable;
