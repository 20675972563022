import { create } from "zustand";
import { authApi } from "../api/auth.api";
import { persist } from "zustand/middleware";
interface AuthStore {
  authToken?: string;
  uuid?: string;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  signOut: () => void;
}

const useAuthStore = create<AuthStore>()(
  persist(
    (set) => ({
      authToken: undefined,
      signIn: async function (email: string, password: string) {
        const result = await authApi.signIn({ email: email, password: password });
        set({ authToken: result.data.accessToken, uuid: result.data.accountUuid });
      },
      signUp: async function (email: string, password: string) {
        const result = await authApi.signUp({ email: email, password: password });
        set({ authToken: result.data.accessToken, uuid: result.data.accountUuid });
      },
      signOut: function () {
        set({ authToken: undefined });
      },
    }),
    {
      name: "auth-store",
    },
  ),
);

export default useAuthStore;
