import { apiV1 } from "./api-v1";
import { AccountType } from "../models/users/account-type";
import { Shop } from "../models/users/shop";
import { SignInMethod } from "../models/sign-in-method";
import { PaginationResult } from "../models/pagination";

export const shopApi = {
  endpoint: "/shops",
  async get(uuid: string): Promise<Shop> {
    return await apiV1.get(`${this.endpoint}/${uuid}`);
  },
  async getShopsByPagination(pageIndex: number, pageSize: number): Promise<PaginationResult<Shop>> {
    let response = await apiV1.get(`${this.endpoint}?pageIndex=${pageIndex}&pageSize=${pageSize}`);
    return response.data;
  },
  async delete(uuid: string): Promise<boolean> {
    const response = await apiV1.delete(`${this.endpoint}/${uuid}`);
    return !!response.data;
  },
  async create(shop: Shop): Promise<Shop> {
    shop.profile.type = AccountType.shop;
    const response = await apiV1.post("/auth/shops/sign-up", {
      profile: shop.profile,
      password: shop.password,
      signInMethod: SignInMethod.EMAIL_PASSWORD,
    });
    return response.data;
  },
  async update(shop: Shop): Promise<Shop> {
    shop.profile.type = AccountType.shop;
    return await apiV1.put(`${this.endpoint}/${shop.uuid}`, {
      profile: shop.profile,
    });
  },
};
