import { apiV1 } from "./api-v1";
import { Operator } from "../models/users/operator";
import { SignInMethod } from "../models/sign-in-method";
import { AccountType } from "../models/users/account-type";
import { PaginationResult } from "../models/pagination";

export const operatorApi = {
  endpoint: "/operators",
  async get(uuid: string): Promise<Operator> {
    return await apiV1.get(`${this.endpoint}/${uuid}`);
  },
  async getOperatorsByPagination(pageIndex: number, pageSize: number): Promise<PaginationResult<Operator>> {
    let response = await apiV1.get(`${this.endpoint}?pageIndex=${pageIndex}&pageSize=${pageSize}`);
    return response.data;
  },
  async delete(uuid: string): Promise<boolean> {
    const response = await apiV1.delete(`${this.endpoint}/${uuid}`);
    return !!response.data;
  },

  async create(operator: Operator): Promise<Operator> {
    operator.profile.type = AccountType.operator;
    const response = await apiV1.post("/auth/operators/sign-up", {
      profile: operator.profile,
      password: operator.password,
      signInMethod: SignInMethod.EMAIL_PASSWORD,
    });
    return response.data;
  },

  async update(operator: Operator): Promise<Operator> {
    return await apiV1.put(`${this.endpoint}/${operator.uuid}`, {
      profile: operator.profile,
    });
  },
};
