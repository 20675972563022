import { create } from "zustand";
import { ScannerDevice } from "../models/devices/scanner-device";
import { scannerApi } from "../api/scanner.api";

type ScannerStoreEntity = { [key: string]: ScannerDevice };
interface ScannerStore {
  entities: ScannerStoreEntity;
  totalEntities: number;
  create: (scanner: ScannerDevice) => void;
  update: (scanner: ScannerDevice) => void;
  get: (page: number, size: number) => Promise<ScannerDevice[]>;
  getByUuid: (uuid: string) => void;
  delete: (uuid: string) => void;
}

const useScannerStore = create<ScannerStore>((set, get) => ({
  entities: {},
  totalEntities: 0,
  create: async function (scanner: ScannerDevice) {
    const scan = await scannerApi.createScanner(scanner);
    set((state) => {
      return {
        entities: { ...state.entities, ...{ [scan.uuid]: scan } },
        totalEntities: state.totalEntities + 1,
      };
    });
  },
  update: async function (scanner: ScannerDevice) {
    const updatedScanner: ScannerDevice = await scannerApi.updateScanner(scanner);
    set((state) => {
      return {
        entities: { ...state.entities, ...{ [updatedScanner.uuid]: updatedScanner } },
      };
    });
  },
  get: async function (page: number, size: number) {
    const data = await scannerApi.getScannersByPagination(page, size);
    const scanners = data.data;
    const current: ScannerStoreEntity = get().entities;

    scanners.forEach((scan) => {
      current[scan.uuid] = scan;
    });
    set({ entities: current, totalEntities: data.metadata.totalCount });
    return scanners;
  },
  getByUuid: async function (uuid: string) {
    const scanner = await scannerApi.getScannerByUuid(uuid);
    const current = get().entities;
    current[scanner.uuid] = scanner;
    set({ entities: current });
  },
  delete: async function (uuid: string) {
    let response = await scannerApi.deleteScanner(uuid);
    if (response) {
      set((state) => {
        delete state.entities[uuid];
        return {
          entities: { ...state.entities },
          totalEntities: state.totalEntities - 1,
        };
      });
    }
  },
}));

export const selectScannerList = (state: ScannerStore) => Object.values(state.entities);

export default useScannerStore;
