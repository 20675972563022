import React, { useEffect, useState } from "react";
import { Button, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { usePackStore } from "../../stores/pack.store";
import PackTable from "./PackTable";
import { useNavigate } from "react-router-dom";
import { packagingPath } from "../../routes";
import Title from "antd/es/typography/Title";
import { Pack } from "../../models/boxes/pack";

const PACKS_PAGE_SIZE = 10;

const PackPage = () => {
  const navigate = useNavigate();
  const getAllPack = usePackStore((state) => state.getAll);
  const totalPacks = usePackStore((state) => state.totalEntities);

  const [packsList, setPacksList] = useState<Pack[]>([]);
  const [page, changePage] = useState<number>(0);

  useEffect(() => {
    getAllPack(page, PACKS_PAGE_SIZE).then((packs) => {
      setPacksList(packs);
    });
  }, [totalPacks, getAllPack, page]);

  return (
    <div>
      <Title>Paquetes</Title>
      <Space>
        <Button
          onClick={() => {
            navigate(packagingPath);
          }}
          type={"primary"}
        >
          <PlusOutlined />
          Añadir paquete
        </Button>
      </Space>
      <br />
      <br />
      <PackTable
        data={packsList}
        pagination={{
          pageSize: PACKS_PAGE_SIZE,
          total: totalPacks,
          showSizeChanger: false,
        }}
        onChange={(pagination) => {
          getAllPack(pagination.current! - 1, PACKS_PAGE_SIZE).then((packs) => {
            setPacksList(packs);
            changePage(pagination.current! - 1);
          });
        }}
      />
    </div>
  );
};

export default PackPage;
