import { PaginationProps, Space, Switch, Table } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import React from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { generateBinDeviceDetailPath } from "../../routes";
import BinDeviceDeleteButton from "./BinDeviceDeleteButton";
import { BinDevice } from "../../models/devices/bin-device";

const columns: ColumnsType<BinDevice> = [
  {
    title: "UUID",
    dataIndex: "uuid",
    key: "uuid",
  },
  {
    title: "Nombre dispositivo",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Funcionamiento MQTT",
    dataIndex: ["mqttUser", "enabled"],
    key: "enabled",
    render: (value, record) => (
      <Switch
        disabled
        checked={value}
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        defaultChecked
      />
    ),
  },
  {
    title: "Fecha creación",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: "Borrar",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <BinDeviceDeleteButton uuid={record.uuid} />
      </Space>
    ),
    onCell: () => {
      return {
        onClick: (event) => {
          event.stopPropagation();
        },
      };
    },
  },
];

type BinDevicesTableProp = {
  data: readonly BinDevice[];
  pagination: PaginationProps;
  onChange: (pagination: PaginationProps) => void;
};
const BinDevicesTable = (props: BinDevicesTableProp) => {
  const navigate = useNavigate();

  const onRow = (record: BinDevice, index: number | undefined) => {
    return {
      onClick: () => {
        navigate(generateBinDeviceDetailPath(record.uuid));
      },
    };
  };
  return (
    <Table
      columns={columns}
      dataSource={props.data}
      rowKey={"uuid"}
      onRow={onRow}
      pagination={props.pagination}
      onChange={props.onChange}
    />
  );
};

export default BinDevicesTable;
