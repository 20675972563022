import { ColumnProps } from "antd/es/table";
import { Market } from "../../models/market/market";
import { PaginationProps, Space, Table } from "antd";
import MarketDeleteButton from "./MarketDeleteButton";
import MarketUpdateButton from "./MarketUpdateButton";

const columns: ColumnProps<Market>[] = [
  {
    title: "UUID",
    dataIndex: ["uuid"],
  },
  {
    title: "Nombre",
    dataIndex: ["name"],
  },
  {
    title: "Dirección",
    dataIndex: ["address"],
  },
  {
    title: "Editar",
    render: (_, record) => (
      <Space size="large">
        <MarketUpdateButton market={record} />
      </Space>
    ),
  },
  {
    title: "Borrar",
    render: (_, record) => (
      <Space size="large">
        <MarketDeleteButton uuid={record.uuid} />
      </Space>
    ),
  },
];

type MarketTableProps = {
  data: readonly Market[];
  pagination: PaginationProps;
  onChange: (pagination: PaginationProps) => void;
};
const MarketsTable = (props: MarketTableProps) => (
  <Table
    columns={columns}
    dataSource={props.data}
    rowKey={(record) => record.uuid}
    pagination={props.pagination}
    onChange={props.onChange}
  />
);

export default MarketsTable;
