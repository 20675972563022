import { apiV1 } from "./api-v1";
import { Market } from "../models/market/market";
import { PaginationResult } from "../models/pagination";

export const marketApi = {
  endpoint: "/markets",
  async get(uuid: string): Promise<Market> {
    return await apiV1.get(`${this.endpoint}/${uuid}`);
  },

  async getByPagination(pageIndex: number, pageSize: number): Promise<PaginationResult<Market>> {
    const response = await apiV1.get(`${this.endpoint}?pageIndex=${pageIndex}&pageSize=${pageSize}`);
    return response.data;
  },

  async create(market: Market): Promise<Market> {
    const response = await apiV1.post(`${this.endpoint}`, market);
    return response.data;
  },

  async update(market: Market): Promise<Market> {
    return await apiV1.put(`${this.endpoint}/${market.uuid}`, {
      name: market.name,
      address: market.address,
    });
  },

  async delete(uuid: string): Promise<void> {
    await apiV1.delete(`${this.endpoint}/${uuid}`);
  },
};
