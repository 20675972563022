import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import ModalOperatorComponent from "../../components/users/operators/OperatorFormModal";
import { Operator } from "../../models/users/operator";
import useOperatorsStore from "../../stores/operators.store";

const CreateOperatorButton = () => {
  const createOperator = useOperatorsStore((state) => state.create);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onFinish = async (operator: Operator) => {
    createOperator(operator);
    setIsModalOpen(false);
  };
  return (
    <div>
      <Button type="primary" onClick={() => setIsModalOpen(true)}>
        <PlusOutlined /> Añadir operario
      </Button>
      <ModalOperatorComponent
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        handleSubmit={onFinish}
        operator={null}
        isCreate={true}
      />
    </div>
  );
};

export default CreateOperatorButton;
