import { Bin } from "../models/bin";
import { create } from "zustand";
import { binApi } from "../api/bin.api";

type BinStoreEntity = { [key: string]: Bin };
interface BinsStore {
  entities: BinStoreEntity;
  totalEntities: number;
  create: (bin: Bin) => void;
  delete: (uuid: string) => void;
  get: (page: number, interval: number) => Promise<Bin[]>;
  getByUuid: (uuid: string) => Promise<Bin>;
  update: (bin: Bin) => void;
  updateDevice: (bin: Bin) => void;
}

const useBinsStore = create<BinsStore>()((set, get) => ({
  entities: {},
  totalEntities: 0,
  create: async (bin: Bin) => {
    const createdBin = await binApi.create(bin);
    set((state) => {
      return {
        entities: {
          ...state.entities,
          ...{ [createdBin.uuid]: createdBin },
        },
        totalEntities: state.totalEntities + 1,
      };
    });
  },
  delete: async (uuid: string) => {
    const response = await binApi.delete(uuid);
    if (response) {
      set((state) => {
        delete state.entities[uuid];
        return {
          entities: { ...state.entities },
          totalEntities: state.totalEntities - 1,
        };
      });
    }
  },
  get: async (page: number, interval: number) => {
    const data = await binApi.getBinsByPagination(page, interval);
    const currentOperators = get().entities;
    const bins = data.data;

    bins.forEach((bin) => {
      currentOperators[bin.uuid] = bin;
    });

    set({ entities: currentOperators, totalEntities: data.metadata.totalCount });
    return bins;
  },
  getByUuid: async function (uuid: string): Promise<Bin> {
    const bin = await binApi.get(uuid);
    set((state) => {
      return {
        entities: { ...state.entities, ...{ [bin.uuid]: bin } },
      };
    });
    return bin;
  },
  update: async (bin: Bin) => {
    const updatedBin: Bin = await binApi.update(bin);
    set((state) => {
      return {
        entities: { ...state.entities, ...{ [updatedBin.uuid]: updatedBin } },
        totalEntities: state.totalEntities - 1,
      };
    });
  },
  updateDevice: async (bin: Bin) => {
    const updatedBin: Bin = await binApi.updateDevice(bin);
    set((state) => {
      return {
        entities: { ...state.entities, ...{ [updatedBin.uuid]: updatedBin } },
        totalEntities: state.totalEntities - 1,
      };
    });
  },
}));

export default useBinsStore;
