import { apiV1 } from "./api-v1";
import { PaginationResult } from "../models/pagination";
import { BinDevice } from "../models/devices/bin-device";
import { DeviceType } from "../models/devices/device-type";

export type BinCreate = Omit<BinDevice, "uuid" | "createdAt">;
export const binDeviceApi = {
  endpoint: "/device-bins",
  async createBinDevice(bin: BinCreate): Promise<BinDevice> {
    bin.data.type = DeviceType.bin;
    const result = await apiV1.post(`${this.endpoint}`, bin);
    return result.data;
  },
  async getBinDeviceByUuid(uuid: string): Promise<BinDevice> {
    const result = await apiV1.get(`${this.endpoint}/${uuid}`);
    return result.data;
  },
  async getBinDevicesByPagination(pageIndex: number, pageSize: number): Promise<PaginationResult<BinDevice>> {
    let response = await apiV1.get(`${this.endpoint}?pageIndex=${pageIndex}&pageSize=${pageSize}`);
    return response.data;
  },
  async deleteBinDevice(uuid: string): Promise<boolean> {
    let response = await apiV1.delete(`${this.endpoint}/${uuid}`);
    return !!response.data;
  },
  async updateBinDevice(bin: BinDevice): Promise<BinDevice> {
    let response = await apiV1.put(`${this.endpoint}/${bin.uuid}`, {
      data: bin.data,
    });
    return response.data;
  },
};
