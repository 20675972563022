import { DeleteOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import useCustomerStore from "../../stores/customers.store";

interface CustomerDeleteProps {
  uuid: string;
}

const CustomerDeleteButton = ({ uuid }: CustomerDeleteProps) => {
  const deleteCustomer = useCustomerStore((state) => state.delete);

  const onClick = async () => {
    deleteCustomer(uuid);
  };
  return (
    <>
      <Space>
        <Button onClick={onClick}>
          <DeleteOutlined />
        </Button>
      </Space>
    </>
  );
};

export default CustomerDeleteButton;
