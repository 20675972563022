import { DeleteOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import useOperatorsStore from "../../stores/operators.store";

interface OperatorDeleteMessageProps {
  uuid: string;
}

const OperatorDeleteButton = ({ uuid }: OperatorDeleteMessageProps) => {
  const deleteOperator = useOperatorsStore((state) => state.delete);

  const onClick = async () => {
    deleteOperator(uuid);
  };
  return (
    <>
      <Space>
        <Button onClick={onClick}>
          <DeleteOutlined />
        </Button>
      </Space>
    </>
  );
};

export default OperatorDeleteButton;
