import useScannerStore from "../../stores/scanner.store";
import { Button, Modal, Space } from "antd";
import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import React from "react";

const { confirm } = Modal;

interface ScannerDeleteProps {
  uuid: string;
  onDelete?: () => void;
}

const ScannerDeleteButton = ({ uuid, onDelete }: ScannerDeleteProps) => {
  const deleteScanner = useScannerStore((state) => state.delete);
  const buttonType = onDelete ? "primary" : "default";

  const onClick = async () => {
    confirm({
      title: "Do you want to delete these scanner?",
      icon: <ExclamationCircleFilled />,
      onOk: async function () {
        deleteScanner(uuid);
        if (onDelete) {
          onDelete();
        }
      },
    });
  };

  return (
    <>
      <Space>
        <Button onClick={onClick} type={buttonType}>
          {" "}
          <DeleteOutlined />
          {onDelete ? "Eliminar scanner" : ""}
        </Button>
      </Space>
    </>
  );
};
export default ScannerDeleteButton;
