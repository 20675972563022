import { Bin } from "../models/bin";
import { PaginationResult } from "../models/pagination";
import { apiV1 } from "./api-v1";

export const binApi = {
  endpoint: "/bins",
  async get(uuid: string): Promise<Bin> {
    return await apiV1.get(`${this.endpoint}/${uuid}`);
  },
  async getBinsByPagination(pageIndex: number, pageSize: number): Promise<PaginationResult<Bin>> {
    const response = await apiV1.get(`${this.endpoint}?pageIndex=${pageIndex}&pageSize=${pageSize}`);
    return response.data;
  },
  async delete(uuid: string): Promise<boolean> {
    const response = await apiV1.delete(`${this.endpoint}/${uuid}`);
    return !!response.data;
  },

  async create(bin: Bin): Promise<Bin> {
    const response = await apiV1.post(`${this.endpoint}`, bin);
    return response.data;
  },

  async update(bin: Bin): Promise<Bin> {
    return await apiV1.put(`${this.endpoint}/${bin.uuid}`, {
      name: bin.name,
      address: bin.address,
      latitude: bin.latitude,
      longitude: bin.longitude,
    });
  },
  async updateDevice(bin: Bin): Promise<Bin> {
    return await apiV1.put(`${this.endpoint}/${bin.uuid}/device`, {
      uuid: bin.deviceUuid,
    });
  },
};
