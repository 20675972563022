import { Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useState } from "react";
import ModalOperatorComponent from "../../components/users/operators/OperatorFormModal";
import { Operator } from "../../models/users/operator";
import useOperatorsStore from "../../stores/operators.store";
import { AccountType } from "../../models/users/account-type";

interface EditProps {
  operator: Operator;
}
const EditOperatorButton = (props: EditProps) => {
  const updateOperator = useOperatorsStore((state) => state.update);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onFinish = async (values: Operator) => {
    values.uuid = props.operator.uuid;
    values.profile.type = AccountType.operator;
    updateOperator(values);
    setIsModalOpen(false);
  };
  return (
    <div>
      <Button onClick={() => setIsModalOpen(true)}>
        <EditOutlined />
      </Button>
      <ModalOperatorComponent
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        handleSubmit={onFinish}
        operator={props.operator}
        isCreate={false}
      />
    </div>
  );
};

export default EditOperatorButton;
