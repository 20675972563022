import { AxiosResponse } from "axios";
import { apiV1 } from "./api-v1";
import { Auth } from "../models/auth";

export type LoginRequestData = {
  email: string;
  password: string;
};

export type RegisterRequestData = {
  email: string;
  password: string;
};

export const authApi = {
  signIn: async function (params: LoginRequestData): Promise<AxiosResponse<Auth>> {
    return await apiV1.post<LoginRequestData, AxiosResponse<Auth>>("/auth/sign-in", params);
  },

  signUp: async function (params: RegisterRequestData): Promise<AxiosResponse<Auth>> {
    return await apiV1.post<RegisterRequestData, AxiosResponse<Auth>>("/auth/sign-up", {
      email: params.email,
      password: params.password,
    });
    //return apiV1.get("hello");
  },
};
