import React, { useEffect, useState } from "react";
import useBoxStore from "../../stores/box.store";
import SoldBoxTable from "./SoldBoxTable";
import Title from "antd/es/typography/Title";
import { Box } from "../../models/boxes/box";

const BOXES_PAGE_SIZE = 10;

const BoxPage = () => {
  const [page, setPage] = useState(0);
  const getBoxes = useBoxStore((state) => state.getSoldBoxes);
  const totalBoxes = useBoxStore((state) => state.totalSoldEntities);

  const [boxesList, setBoxesList] = useState<Box[]>([]);
  useEffect(() => {
    getBoxes(page, BOXES_PAGE_SIZE).then((boxes) => {
      setBoxesList(boxes);
    });
  }, [totalBoxes, getBoxes, page]);

  return (
    <div>
      <Title>Envases vendidos</Title>
      <br />
      <br />
      <SoldBoxTable
        data={boxesList}
        pagination={{
          pageSize: BOXES_PAGE_SIZE,
          total: totalBoxes,
          showSizeChanger: false,
          current: page + 1,
        }}
        onChange={(pagination) => {
          const newPage = pagination.current! - 1;
          setPage(newPage);
        }}
      ></SoldBoxTable>
    </div>
  );
};

export default BoxPage;
