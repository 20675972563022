import { Operator } from "../../../models/users/operator";
import { Button, Form, Input} from "antd";

interface FormOperatorProps {
  operator: Operator | null;
  handleSubmit: (values: Operator) => void;
  buttonMessage: string;
}
const OperatorForm = ({ operator, handleSubmit, buttonMessage }: FormOperatorProps) => {
  return (
    <Form<Operator>
      layout="vertical"
      initialValues={operator ? operator : {}}
      autoComplete="off"
      onFinish={handleSubmit}
    >
      <Form.Item label="Nombre" name={["profile", "name"]} rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item label="Identificación" name={["profile", "identification"]}>
        <Input />
      </Form.Item>

      <Form.Item
        label="Email"
        name={["profile", "email"]}
        rules={[
          { required: true },
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Teléfono" name={["profile", "phone"]} rules={[]}>
        <Input />
      </Form.Item>

      <Form.Item label="Dirección" name={["profile", "address"]} rules={[]}>
        <Input />
      </Form.Item>
      <Form.Item label="Código postal" name={["profile", "zipCode"]} rules={[]}>
        <Input />
      </Form.Item>
      <Form.Item label="Contraseña" name={"password"} rules={[]} hidden={!!operator}>
        <Input.Password />
      </Form.Item>

      <Button type={"primary"} block htmlType="submit">
        {buttonMessage}
      </Button>
    </Form>
  );
};

export default OperatorForm;
