import { create } from "zustand";
import { Operator } from "../models/users/operator";
import { operatorApi } from "../api/operator.api";

type OperatorStoreEntity = { [key: string]: Operator };
interface OperatorsStore {
  entities: OperatorStoreEntity;
  totalEntities: number;
  create: (operator: Operator) => void;
  delete: (uuid: string) => void;
  getAll: (page: number, interval: number) => Promise<Operator[]>;
  get: (uuid: string) => Promise<Operator>;
  update: (operator: Operator) => void;
}

const useOperatorsStore = create<OperatorsStore>()((set, get) => ({
  entities: {},
  totalEntities: 0,
  create: async (operator: Operator) => {
    const createdOperator = await operatorApi.create(operator);
    set((state) => {
      return {
        entities: {
          ...state.entities,
          ...{ [createdOperator.uuid]: createdOperator },
        },
        totalEntities: state.totalEntities + 1,
      };
    });
  },
  delete: async (uuid: string) => {
    await operatorApi.delete(uuid);
    set((state) => {
      delete state.entities[uuid];
      return {
        entities: { ...state.entities },
        totalEntities: state.totalEntities - 1,
      };
    });
  },
  getAll: async (page: number, interval: number) => {
    const data = await operatorApi.getOperatorsByPagination(page, interval);
    const currentOperators = get().entities;
    const operators = data.data;

    operators.forEach((operator) => {
      currentOperators[operator.uuid] = operator;
    });

    set({ entities: currentOperators, totalEntities: data.metadata.totalCount });
    return operators;
  },
  get: async function (uuid: string): Promise<Operator> {
    const operator = await operatorApi.get(uuid);
    set((state) => {
      return {
        entities: { ...state.entities, ...{ [operator.uuid]: operator } },
      };
    });
    return operator;
  },
  update: async (operator: Operator) => {
    const updatedOperator: Operator = await operatorApi.update(operator);
    set((state) => {
      return {
        entities: { ...state.entities, ...{ [updatedOperator.uuid]: updatedOperator } },
        totalEntities: state.totalEntities - 1,
      };
    });
  },
}));

export const selectOperatorList = (state: OperatorsStore) => Object.values(state.entities);

export default useOperatorsStore;
