import { PaginationProps, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { Box } from "../../models/boxes/box";
import dayjs from "dayjs";
import React from "react";
import BoxDeleteButton from "./BoxDeleteButton";
import BoxUpdateButton from "./BoxUpdateButton";

const columns: ColumnsType<Box> = [
  {
    title: "UUID",
    dataIndex: "uuid",
  },
  {
    title: "Estado actual",
    dataIndex: "state",
    key: "state",
  },
  {
    title: "Fecha creación",
    dataIndex: "createdAt",
    render: (value) => dayjs(value).format("YYYY-MM-DD"),
  },
  {
    title: "Fecha actualización",
    dataIndex: "updateAt",
    render: (value) => dayjs(value).format("YYYY-MM-DD"),
  },
  {
    title: "Editar",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <BoxUpdateButton box={record} />
      </Space>
    ),
  },
  {
    title: "Borrar",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <BoxDeleteButton uuid={record.uuid} />
      </Space>
    ),
  },
];

type BoxTableProps = {
  data: readonly Box[];
  pagination: PaginationProps;
  onChange: (pagination: PaginationProps) => void;
};
const BoxTable = (props: BoxTableProps) => (
  <Table
    columns={columns}
    dataSource={props.data}
    rowKey={(record) => record.uuid}
    pagination={props.pagination}
    onChange={props.onChange}
  />
);

export default BoxTable;
