import "./PrivatePageSideBar.scss";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Menu, MenuProps } from "antd";
import {
  ShopOutlined,
  ShoppingOutlined,
  UserOutlined,
  UserSwitchOutlined,
  QrcodeOutlined,
  ScanOutlined,
  DeleteOutlined,
  DatabaseOutlined,
  AppstoreOutlined,
  InboxOutlined,
  ClearOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import {
  binPath,
  customerPath,
  operatorPath,
  shopPath,
  scannerDevicePath,
  binDevicePath,
  packPath,
  boxPath,
  soldBoxPath,
  marketPath,
} from "../../routes";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group",
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const rootSubmenuKeys = ["account_manager", "devices_manager", "box_manager"];

const PrivatePageSideBar = () => {
  const [openKeys, setOpenKeys] = useState(["account_manager"]);

  const items: MenuItem[] = [
    getItem("Gestión de cuentas", "account_manager", <UserSwitchOutlined />, [
      getItem(<Link to={operatorPath}>Operators</Link>, "operators", <UserOutlined />),
      getItem(<Link to={customerPath}>Clientes</Link>, "customer", <ShoppingOutlined />),
      getItem(<Link to={shopPath}>Comercios</Link>, "shops", <ShoppingCartOutlined />),
      getItem(<Link to={marketPath}>Mercado</Link>, "market", <ShopOutlined />),
    ]),
    { type: "divider" },
    getItem("Gestión de dispositivos", "devices_manager", <QrcodeOutlined />, [
      getItem(<Link to={scannerDevicePath}>Scanners</Link>, "scanners", <ScanOutlined />),
      getItem(<Link to={binDevicePath}>Recipientes</Link>, "device-bins", <ClearOutlined />),
    ]),
    { type: "divider" },
    getItem("Gestión de envases", "box_manager", <DatabaseOutlined />, [
      getItem(<Link to={packPath}>Paquetes</Link>, "packs", <AppstoreOutlined />),
      getItem(<Link to={boxPath}>Envases</Link>, "boxes", <InboxOutlined />),
      getItem(<Link to={soldBoxPath}>Envases vendidos</Link>, "sold-boxes", <InboxOutlined />),
    ]),
    { type: "divider" },
    getItem(<Link to={binPath}>Papeleras</Link>, "bins", <DeleteOutlined />),
  ];

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={["operators"]}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      className={"PrivatePageSideBar"}
      items={items}
    />
  );
};

export default PrivatePageSideBar;
