import React, { useState } from "react";
import { Button, Form, Input, Modal, Select, Space } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Pack } from "../../models/boxes/pack";
import FormItem from "antd/es/form/FormItem";
import { PackState } from "../../models/boxes/pack-state";
import usePackStore from "../../stores/pack.store";

interface PackEditProps {
  pack: Pack;
}

const PackEditButton = ({ pack }: PackEditProps) => {
  const updateStatePack = usePackStore((state) => state.updateState);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedState, setSelectedState] = useState(pack.state);

  function onChange(value: string) {
    setSelectedState(value as PackState);
  }

  function handleSubmit(updatedPack: Pack) {
    pack.state = selectedState;
    if (selectedState === PackState.Packed) {
      pack.stateData = { operatorUuid: updatedPack.stateData.operatorUuid };
    } else if (selectedState === PackState.Delivered) {
      pack.stateData = {
        operatorUuid: updatedPack.stateData.operatorUuid,
        shopUuid: updatedPack.stateData.shopUuid,
      };
    }
    updateStatePack(pack);
    setIsModalOpen(false);
  }

  return (
    <>
      <Space>
        <Button onClick={() => setIsModalOpen(true)}>
          <EditOutlined />
        </Button>
        <Modal title="Editar estado paquete" open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={null}>
          <Form<Pack>
            layout="vertical"
            name="state-packed"
            onFinish={handleSubmit}
            initialValues={pack}
            autoComplete="off"
          >
            <FormItem label={"Estado actual"}>
              <Select
                defaultValue={selectedState}
                style={{ width: "100%" }}
                onChange={onChange}
                options={Object.values(PackState).map((state) => ({ label: state, value: state }))}
              />
            </FormItem>
            <FormItem name={["stateData", "operatorUuid"]} label={"Identificador del operador"}>
              <Input />
            </FormItem>
            <FormItem
              name={["stateData", "shopUuid"]}
              label={"Identificador del comercio"}
              hidden={selectedState === PackState.Packed}
            >
              <Input />
            </FormItem>
            <FormItem>
              <Button type="primary" htmlType="submit">
                Actualizar
              </Button>
            </FormItem>
          </Form>
        </Modal>
      </Space>
    </>
  );
};

export default PackEditButton;
