import { create } from "zustand";
import { Shop } from "../models/users/shop";
import { shopApi } from "../api/shop.api";

type ShopStoreEntity = { [key: string]: Shop };
interface ShopsStore {
  entities: ShopStoreEntity;
  totalEntities: number;
  create: (shop: Shop) => void;
  delete: (uuid: string) => void;
  getAll: (page: number, size: number) => Promise<Shop[]>;
  get: (uuid: string) => Promise<Shop>;
  update: (shop: Shop) => void;
}

const useShopsStore = create<ShopsStore>()((set, get) => ({
  entities: {},
  totalEntities: 0,
  create: async (shop: Shop) => {
    const createdShop = await shopApi.create(shop);
    set((state) => {
      return {
        entities: { ...state.entities, ...{ [createdShop.uuid]: createdShop } },
        totalEntities: state.totalEntities + 1,
      };
    });
  },
  delete: async (uuid: string) => {
    await shopApi.delete(uuid);
    set((state) => {
      delete state.entities[uuid];
      return {
        entities: { ...state.entities },
        totalEntities: state.totalEntities - 1,
      };
    });
  },
  getAll: async (page: number, size: number) => {
    const data = await shopApi.getShopsByPagination(page, size);
    const currentShops = get().entities;
    const shops = data.data;

    shops.forEach((shop) => {
      currentShops[shop.uuid] = shop;
    });

    set({ entities: currentShops, totalEntities: data.metadata.totalCount });
    return shops;
  },
  get: async function (uuid: string): Promise<Shop> {
    const shop = await shopApi.get(uuid);
    set((state) => {
      return {
        entities: { ...state.entities, ...{ [shop.uuid]: shop } },
      };
    });
    return shop;
  },
  update: async (shop: Shop) => {
    const updatedShop: Shop = await shopApi.update(shop);
    set((state) => {
      return {
        entities: { ...state.entities, ...{ [updatedShop.uuid]: updatedShop } },
        totalEntities: state.totalEntities - 1,
      };
    });
  },
}));

export const selectShopList = (state: ShopsStore) => Object.values(state.entities);

export default useShopsStore;
