import { Flex, Space } from "antd";
import CreateShopButton from "./CreateShopButton";
import React, { useEffect, useState } from "react";
import ShopsTable from "./ShopsTable";
import useShopsStore from "../../stores/shops.store";
import Title from "antd/es/typography/Title";
import { Shop } from "../../models/users/shop";

export const SHOPS_PAGE_SIZE = 10;

const ShopPage = () => {
  const getShops = useShopsStore((state) => state.getAll);
  const totalShops = useShopsStore((state) => state.totalEntities);

  const [shopList, setShopsList] = useState<Shop[]>([]);
  const [page, changePage] = useState<number>(0);

  useEffect(() => {
    getShops(page, SHOPS_PAGE_SIZE).then((shops) => {
      setShopsList(shops);
    });
  }, [totalShops, getShops, page]);

  return (
    <Flex vertical>
      <Title>Comercios</Title>
      <Space>
        <CreateShopButton />
      </Space>
      <br />
      <br />
      <ShopsTable
        data={shopList}
        pagination={{
          pageSize: SHOPS_PAGE_SIZE,
          total: totalShops,
          showSizeChanger: false,
        }}
        onChange={(pagination) => {
          getShops(pagination.current! - 1, SHOPS_PAGE_SIZE).then((shops) => {
            setShopsList(shops);
            changePage(pagination.current! - 1);
          });
        }}
      ></ShopsTable>
    </Flex>
  );
};

export default ShopPage;
