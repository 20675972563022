import React, { useEffect, useState } from "react";
import Title from "antd/es/typography/Title";
import { Market } from "../../models/market/market";
import { useMarketStore } from "../../stores/market.store";
import MarketTable from "./MarketTable";
import { Flex, Space } from "antd";
import CreateMarketButton from "./MarketCreateButton";

export const MARKET_PAGE_SIZE = 10;

export const MarketPage = () => {
  const getMarkets = useMarketStore((state) => state.getByPagination);
  const totalMarkets = useMarketStore((state) => state.totalEntities);

  const [marketsList, setMarketsList] = useState<Market[]>([]);
  const [page, changePage] = useState<number>(0);

  useEffect(() => {
    getMarkets(page, MARKET_PAGE_SIZE).then((markets) => {
      setMarketsList(markets);
    });
  }, [totalMarkets, getMarkets, page]);

  return (
    <Flex vertical>
      <Title>Mercados</Title>
      <Space size={"large"}>
        <CreateMarketButton />
      </Space>
      <br />
      <br />
      <MarketTable
        data={marketsList}
        pagination={{
          pageSize: MARKET_PAGE_SIZE,
          total: totalMarkets,
          showSizeChanger: false,
        }}
        onChange={(pagination) => {
          getMarkets(pagination.current! - 1, MARKET_PAGE_SIZE).then((markets) => {
            setMarketsList(markets);
            changePage(pagination.current! - 1);
          });
        }}
      ></MarketTable>
    </Flex>
  );
};
