import { PaginationProps, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Operator } from "../../models/users/operator";
import OperatorDeleteButton from "./OperatorDeleteButton";
import EditOperatorButton from "./EditOperatorButton";

const columns: ColumnsType<Operator> = [
  {
    title: "UUID",
    dataIndex: ["uuid"],
  },
  {
    title: "Nombre",
    dataIndex: ["profile", "name"],
  },
  {
    title: "DNI",
    dataIndex: ["profile", "identification"],
  },
  {
    title: "Email",
    dataIndex: ["profile", "email"],
  },
  {
    title: "Teléfono",
    dataIndex: ["profile", "phone"],
  },
  {
    title: "Dirección",
    dataIndex: ["profile", "address"],
  },
  {
    title: "Código postal",
    dataIndex: ["profile", "zipCode"],
  },
  {
    title: "Editar",
    render: (_, record) => (
      <Space size="large">
        <EditOperatorButton operator={record} />
      </Space>
    ),
  },
  {
    title: "Borrar",
    render: (_, record) => (
      <Space size="large">
        <OperatorDeleteButton uuid={record.uuid} />
      </Space>
    ),
  },
];

type OperatorTableProps = {
  data: readonly Operator[];
  pagination: PaginationProps;
  onChange: (pagination: PaginationProps) => void;
};
const OperatorsTable = (props: OperatorTableProps) => (
  <Table
    columns={columns}
    dataSource={props.data}
    rowKey={(record) => record.uuid}
    pagination={props.pagination}
    onChange={props.onChange}
  />
);

export default OperatorsTable;
