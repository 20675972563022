import { apiV1 } from "./api-v1";
import { ScannerDevice } from "../models/devices/scanner-device";
import { DeviceType } from "../models/devices/device-type";
import { PaginationResult } from "../models/pagination";

export const scannerApi = {
  endpoint: "/device-scanners",
  async createScanner(scanner: ScannerDevice): Promise<ScannerDevice> {
    scanner.data.type = DeviceType.scanner;
    const result = await apiV1.post(`${this.endpoint}`, {
      name: scanner.name,
      data: scanner.data,
    });
    return result.data;
  },
  async getScannerByUuid(uuid: string): Promise<ScannerDevice> {
    const result = await apiV1.get(`${this.endpoint}/${uuid}`);
    return result.data;
  },
  async getScannersByPagination(pageIndex: number, pageSize: number): Promise<PaginationResult<ScannerDevice>> {
    let response = await apiV1.get(`${this.endpoint}?pageIndex=${pageIndex}&pageSize=${pageSize}`);
    return response.data;
  },
  async deleteScanner(uuid: string): Promise<boolean> {
    let response = await apiV1.delete(`${this.endpoint}/${uuid}`);
    return !!response.data;
  },
  async updateScanner(scanner: ScannerDevice): Promise<ScannerDevice> {
    let response = await apiV1.put(`${this.endpoint}/${scanner.uuid}`, {
      data: scanner.data,
    });
    return response.data;
  },
};
