import { ScannerDevice } from "../../models/devices/scanner-device";
import { BinDevice } from "../../models/devices/bin-device";
import { Card, Descriptions, DescriptionsProps, Input } from "antd";

interface TechnicalSectionProps {
  device: ScannerDevice | BinDevice | undefined;
}
const TechnicalSection = ({ device }: TechnicalSectionProps) => {
  const items: DescriptionsProps["items"] = [
    { key: "deviceUuid", label: "Device UUID", children: <span>{device?.uuid}</span> },
    { key: "cpuSerial", label: "Cpu Serial", children: <span>{device?.data.cpuSerial}</span> },
    { key: "cm4Model", label: "CM4 Model", children: <span>{device?.data.cm4Model}</span> },
    { key: "simIccid", label: "SIM ICCID", children: <span>{device?.data.simIccid}</span> },
    {
      key: "embeddedHardwareVersion",
      label: "Embedded Hardware Version",
      children: <span>{device?.data.embeddedHardwareVersion}</span>,
    },
    {
      key: "embeddedSoftwareVersion",
      label: "Embedded Software Version",
      children: <span>{device?.data.embeddedSoftwareVersion}</span>,
    },
    { key: "osUsername", label: "OS Username", children: <span>{device?.data.osUsername}</span> },
    {
      key: "osPassword",
      label: "OS Password",
      children: (
        <Input.Password value={device?.data.osPassword} bordered={false} visibilityToggle={false}></Input.Password>
      ),
    },
    { key: "wifiSsid", label: "WIFI SSID", children: <span>{device?.data.wifiSsid}</span> },
    {
      key: "wifiPassword",
      label: "WIFI Password",
      children: (
        <Input.Password value={device?.data.wifiPassword} bordered={false} visibilityToggle={false}></Input.Password>
      ),
    },
    { key: "hostname", label: "Hostname", children: <span>{device?.data.hostname}</span> },
    { key: "comment", label: "Comment", children: <span>{device?.data.comment}</span> },
  ];

  return (
    <Card title="Device Details">
      <Descriptions column={2} items={items} />
    </Card>
  );
};

export default TechnicalSection;
