import React, { useEffect, useState } from "react";
import { Button, Space } from "antd";
import useBoxStore from "../../stores/box.store";
import BoxTable from "./BoxTable";
import { PlusOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import { Box } from "../../models/boxes/box";

const BOXES_PAGE_SIZE = 10;

const BoxPage = () => {
  const getBoxes = useBoxStore((state) => state.get);
  const createBox = useBoxStore((state) => state.create);
  const totalBoxes = useBoxStore((state) => state.totalEntities);

  const [boxesList, setBoxesList] = useState<Box[]>([]);
  const [page, changePage] = useState<number>(0);

  useEffect(() => {
    getBoxes(page, BOXES_PAGE_SIZE).then((boxes) => {
      setBoxesList(boxes);
    });
  }, [totalBoxes, getBoxes, page]);

  function addBox() {
    createBox();
  }

  return (
    <div>
      <Title>Envasos</Title>
      <Space>
        <Button onClick={addBox} type={"primary"}>
          <PlusOutlined />
          Añadir envase
        </Button>
      </Space>
      <br />
      <br />
      <BoxTable
        data={boxesList}
        pagination={{
          pageSize: BOXES_PAGE_SIZE,
          total: totalBoxes,
          showSizeChanger: false,
        }}
        onChange={(pagination) => {
          getBoxes(pagination.current! - 1, BOXES_PAGE_SIZE).then((boxes) => {
            setBoxesList(boxes);
            changePage(pagination.current! - 1);
          });
        }}
      ></BoxTable>
    </div>
  );
};

export default BoxPage;
