import React from "react";
import { Button, Modal, Space } from "antd";
import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import useBinDeviceStore from "../../stores/bin-device.store";

const { confirm } = Modal;

interface BinDeviceDeleteProps {
  uuid: string;
  onDelete?: () => void;
}

const BinDeviceDeleteButton = ({ uuid, onDelete }: BinDeviceDeleteProps) => {
  const deleteBin = useBinDeviceStore((state) => state.delete);
  const buttonType = onDelete ? "primary" : "default";

  const onClick = async () => {
    confirm({
      title: "Quieres eliminar este recipiente?",
      icon: <ExclamationCircleFilled />,
      onOk: async function () {
        deleteBin(uuid);
        if (onDelete) {
          onDelete();
        }
      },
    });
  };

  return (
    <>
      <Space>
        <Button onClick={onClick} type={buttonType}>
          {" "}
          <DeleteOutlined />
          {onDelete ? "Eliminar recipiente  " : ""}
        </Button>
      </Space>
    </>
  );
};

export default BinDeviceDeleteButton;
