import { apiV1 } from "./api-v1";
import { AccountType } from "../models/users/account-type";
import { Customer } from "../models/users/customer";
import { PaginationResult } from "../models/pagination";

export const customersApi = {
  endpoint: "/customers",
  async get(uuid: string): Promise<Customer> {
    let response = await apiV1.get(`${this.endpoint}/${uuid}`);
    return response.data;
  },
  async getCustomersByPagination(pageIndex: number, pageSize: number): Promise<PaginationResult<Customer>> {
    let response = await apiV1.get(`${this.endpoint}?pageIndex=${pageIndex}&pageSize=${pageSize}`);
    return response.data;
  },
  async delete(uuid: string): Promise<boolean> {
    const response = await apiV1.delete(`${this.endpoint}/${uuid}`);
    return !!response.data;
  },
  async update(customer: Customer): Promise<Customer> {
    customer.profile.type = AccountType.customer;
    let response = await apiV1.put(`${this.endpoint}/${customer.uuid}`, { profile: customer.profile });
    return response.data;
  },
};
