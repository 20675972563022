import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { usePackStore } from "../../stores/pack.store";

const { confirm } = Modal;

interface PackDeleteProps {
  uuid: string;
}

const PackDeleteButton = ({ uuid }: PackDeleteProps) => {
  const deleteBox = usePackStore((state) => state.delete);

  const onClick = async () => {
    confirm({
      title: "Seguro que quieres eliminar este paquete de envases?",
      icon: <ExclamationCircleFilled />,
      onOk: async function () {
        deleteBox(uuid);
      },
    });
  };

  return (
    <>
      <Space>
        <Button onClick={onClick}>
          <DeleteOutlined />
        </Button>
      </Space>
    </>
  );
};

export default PackDeleteButton;
