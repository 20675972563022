import { create } from "zustand";
import { Customer } from "../models/users/customer";
import { customersApi } from "../api/customer.api";

type CustomersStoreEntity = { [key: string]: Customer };
interface CustomerStore {
  entities: CustomersStoreEntity;
  totalEntities: number;
  delete: (uuid: string) => void;
  getByPagination: (page: number, size: number) => Promise<Customer[]>;
  getByUuid: (uuid: string) => Promise<Customer>;
  update: (customer: Customer) => void;
}

const useCustomerStore = create<CustomerStore>()((set, get) => ({
  entities: {},
  totalEntities: 0,
  delete: async (uuid: string) => {
    let response = await customersApi.delete(uuid);
    if (response) {
      set((state) => {
        delete state.entities[uuid];
        return {
          entities: { ...state.entities },
          totalEntities: state.totalEntities - 1,
        };
      });
    }
  },
  getByPagination: async (page: number, size: number) => {
    const data = await customersApi.getCustomersByPagination(page, size);
    const currentCustomers = get().entities;
    const customers = data.data;

    customers.forEach((customer: Customer) => {
      currentCustomers[customer.uuid] = customer;
    });

    set({ entities: currentCustomers, totalEntities: data.metadata.totalCount });
    return customers;
  },
  getByUuid: async function (uuid: string): Promise<Customer> {
    const customer = await customersApi.get(uuid);
    set((state) => {
      return {
        entities: { ...state.entities, ...{ [customer.uuid]: customer } },
      };
    });
    return customer;
  },
  update: async (customer: Customer) => {
    const updateCustomer = await customersApi.update(customer);
    set((state) => {
      delete state.entities[customer.uuid];
      return {
        entities: { ...state.entities, ...{ [updateCustomer.uuid]: updateCustomer } },
        totalEntities: state.totalEntities - 1,
      };
    });
  },
}));

export const selectCustomerList = (state: CustomerStore) => Object.values(state.entities);

export default useCustomerStore;
