import { DeleteOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import useShopsStore from "../../stores/shops.store";

interface ShopDeleteMessageProps {
  uuid: string;
}

const ShopDeleteButton = ({ uuid }: ShopDeleteMessageProps) => {
  const deleteShop = useShopsStore((state) => state.delete);

  const onClick = async () => {
    deleteShop(uuid);
  };

  return (
    <>
      <Space>
        <Button onClick={onClick}>
          <DeleteOutlined />
        </Button>
      </Space>
    </>
  );
};

export default ShopDeleteButton;
