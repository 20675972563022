import React, { useEffect } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import routes, { defaultPath, privatePath } from "./routes";
import "./App.scss";

function App() {
  const element = useRoutes(routes);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(
    function () {
      if (location.pathname === defaultPath) {
        navigate(privatePath);
      }
    },
    [location, navigate],
  );
  return <div id={"App"}>{element}</div>;
}

export default App;
