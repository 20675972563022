import { message } from "antd";
import axios from "axios";
import useAuthStore from "../stores/auth.store";
export const apiV1 = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 15000,
});

apiV1.interceptors.request.use(
  function (config) {
    //Do something before request is sent
    const token = useAuthStore.getState().authToken;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    //Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
apiV1.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    await message.error(JSON.stringify(error.response.data));
    return Promise.reject(error);
  },
);
