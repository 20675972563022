import { PaginationProps, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { Box } from "../../models/boxes/box";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import BoxReturnButton from "./SoldBoxReturnButton";
import useCustomerStore from "../../stores/customers.store";

const columns: ColumnsType<Box> = [
  {
    title: "UUID",
    dataIndex: "uuid"
  },
  {
    title: "Fecha creación",
    dataIndex: "createdAt",
    render: (value) => dayjs(value).format("YYYY-MM-DD")
  },
  {
    title: "Fecha actualización",
    dataIndex: "updateAt",
    render: (value) => dayjs(value).format("YYYY-MM-DD")
  },
  {
    title: "Nombre cliente",
    dataIndex: ["customer", "name"],
    key: "customerName",
  },
  {
    title: "Email cliente",
    dataIndex: ["customer", "email"],
    key: "customerEmail",
  },
  {
    title: "Devolver envase",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <BoxReturnButton box={record} />
      </Space>
    )
  }
];

type BoxTableProps = {
  data: readonly Box[];
  pagination: PaginationProps;
  onChange: (pagination: PaginationProps) => void;
};
const SoldBoxTable = (props: BoxTableProps) => {
  const [combinedData, setCombinedData] = useState<Box[]>([]);
  const getCustomerByUuid = useCustomerStore((state) => state.getByUuid);

  useEffect(() => {
    const data = props.data.map(async (box) => {
      const customerUuid = box.stateData.customerUuid ? box.stateData.customerUuid : "";
      const customer = await getCustomerByUuid(customerUuid);
      const customerName = customer ? customer.profile.name : "";
      const customerEmail = customer ? customer.profile.email : "";
      return { ...box, customer: { name: customerName, email: customerEmail } } as Box;
    });


    // Wait for all promises to resolve
    Promise.all(data).then((resolvedData) => {
      setCombinedData(resolvedData);
    });

  }, [props.data, getCustomerByUuid]);

  return (
    <Table
      columns={columns}
      dataSource={combinedData}
      rowKey={record => `${record.uuid}`}
      pagination={props.pagination}
      onChange={props.onChange}
    />
  );
};

export default SoldBoxTable;
