import {Modal} from "antd";
import {Bin} from "../models/bin";
import BinForm from "./BinForm";

interface BinProps {
    open: boolean;
    onCancel: any;
    handleSubmit: any;
    bin: Bin | null;
    actionText: string;
}
const ModalFormBinComponent = (props: BinProps) => {
    return (
        <Modal title={props.actionText} open={props.open} onCancel={props.onCancel} footer={null}>
            <BinForm bin={props.bin} handleSubmit={props.handleSubmit} buttonMessage={props.actionText} />
        </Modal>
    );
};

export default ModalFormBinComponent;