import { Shop } from "../../../models/users/shop";
import { Button, Form, Input } from "antd";

interface ShopFormProps {
  shop: Shop | null;
  handleSubmit: (values: Shop) => void;
  buttonMessage: string;
}

const ShopForm = ({ shop, handleSubmit, buttonMessage }: ShopFormProps) => {
  return (
    <Form<Shop> layout="vertical" onFinish={handleSubmit} initialValues={shop ? shop : {}} autoComplete="off">
      <Form.Item label="Nombre" name={["profile", "name"]} rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item label="NIF" name={["profile", "identification"]}>
        <Input />
      </Form.Item>

      <Form.Item
        label="Email"
        name={["profile", "email"]}
        rules={[
          { required: true },
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Teléfono" name={["profile", "phone"]} rules={[]}>
        <Input />
      </Form.Item>

      <Form.Item label="Dirección" name={["profile", "address"]} rules={[]}>
        <Input />
      </Form.Item>
      <Form.Item label="Código postal" name={["profile", "zipCode"]} rules={[]}>
        <Input />
      </Form.Item>
      <Form.Item label="Contraseña" name={"password"} rules={[]} hidden={!!shop}>
        <Input.Password />
      </Form.Item>
      <Button type={"primary"} block htmlType="submit">
        {buttonMessage}
      </Button>
    </Form>
  );
};

export default ShopForm;
