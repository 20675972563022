import { PaginationProps, Space, Table } from "antd";
import React from "react";
import { Pack } from "../../models/boxes/pack";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import PackDeleteButton from "./PackDeleteButton";
import PackEditButton from "./PackEditButton";
import PackReplaceButton from "./PackReplaceButton";

interface PackBox {
  pack: string;
  uuid: string;
}

const columnsBox: ColumnsType<PackBox> = [
  {
    title: "UUID",
    key: "uuid",
    dataIndex: "uuid",
  },
  {
    title: "Reemplazar",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <PackReplaceButton pack={record.pack} box={record.uuid} />
      </Space>
    ),
  },
];
const expandedRowBox = (pack: Pack) => {
  const boxes: PackBox[] = pack.boxes.map((box) => {
    return { uuid: box, pack: pack.uuid };
  });
  return <Table columns={columnsBox} dataSource={boxes} />;
};

const columns: ColumnsType<Pack> = [
  {
    title: "UUID",
    dataIndex: "uuid",
  },
  {
    title: "Estado",
    dataIndex: "state",
  },
  {
    title: "Código QR",
    dataIndex: "qrCode",
  },
  {
    title: "Fecha creación",
    dataIndex: "createdAt",
    render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: "Editar estado",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <PackEditButton pack={record} />
      </Space>
    ),
  },

  {
    title: "Borrar",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <PackDeleteButton uuid={record.uuid} />
      </Space>
    ),
  },
];

type PackTableProps = {
  data: readonly Pack[];
  pagination: PaginationProps;
  onChange: (pagination: PaginationProps) => void;
};

const PackTable = (props: PackTableProps) => {
  return (
    <Table
      columns={columns}
      dataSource={props.data}
      expandable={{
        expandedRowRender: (record) => expandedRowBox(record),
        defaultExpandedRowKeys: ["0"],
      }}
      rowKey={(record: Pack) => record.uuid}
      pagination={props.pagination}
      onChange={props.onChange}
    />
  );
};

export default PackTable;
