import { create } from "zustand";
import { BinCreate, binDeviceApi } from "../api/bin-device.api";
import { PaginationResult } from "../models/pagination";
import { BinDevice } from "../models/devices/bin-device";

type BinDeviceStoreEntity = { [key: string]: BinDevice };
interface BinDeviceStore {
  entities: BinDeviceStoreEntity;
  totalEntities: number;
  create: (scanner: BinDevice) => void;
  update: (scanner: BinDevice) => void;
  get: (page: number, size: number) => Promise<BinDevice[]>;
  getByUuid: (uuid: string) => void;
  delete: (uuid: string) => void;
}

const useBinDeviceStore = create<BinDeviceStore>((set, get) => ({
  entities: {},
  totalEntities: 0,
  create: async function (binDevice: BinCreate) {
    const bin = await binDeviceApi.createBinDevice(binDevice);
    set((state) => {
      return {
        entities: { ...state.entities, ...{ [bin.uuid]: bin } },
        totalEntities: state.totalEntities + 1,
      };
    });
  },
  update: async function (binDevice: BinDevice) {
    const updatedBin: BinDevice = await binDeviceApi.updateBinDevice(binDevice);
    set((state) => {
      return {
        entities: { ...state.entities, ...{ [updatedBin.uuid]: updatedBin } },
      };
    });
  },
  get: async function (page: number, size: number) {
    const data: PaginationResult<BinDevice> = await binDeviceApi.getBinDevicesByPagination(page, size);
    const current = get().entities;
    const binDevices = data.data;
    binDevices.forEach((bin) => {
      current[bin.uuid] = bin;
    });

    set({ entities: current, totalEntities: data.metadata.totalCount });
    return binDevices;
  },
  getByUuid: async function (uuid: string) {
    const binDevice = await binDeviceApi.getBinDeviceByUuid(uuid);
    const current = get().entities;
    current[binDevice.uuid] = binDevice;
    set({ entities: current });
  },
  delete: async function (uuid: string) {
    let response = await binDeviceApi.deleteBinDevice(uuid);
    if (response) {
      set((state) => {
        delete state.entities[uuid];
        return {
          entities: { ...state.entities },
          totalEntities: state.totalEntities - 1,
        };
      });
    }
  },
}));

export const selectBinDeviceList = (state: BinDeviceStore) => Object.values(state.entities);

export default useBinDeviceStore;
