import { Button, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Content } from "antd/lib/layout/layout";
import useScannerStore from "../../stores/scanner.store";
import { DeviceType } from "../../models/devices/device-type";
import DeviceFormModal from "../../components/devices/DeviceFormModal";
import ScannerDevicesTable from "./ScannerDeviceTable";
import { ScannerDevice } from "../../models/devices/scanner-device";
import { PlusOutlined } from "@ant-design/icons";

const { Title } = Typography;

const SCANNERS_PAGE_SIZE = 10;
const ScannerDevicePage = () => {
  const createScanner = useScannerStore((state) => state.create);
  const getScanners = useScannerStore((state) => state.get);
  const totalScanners = useScannerStore((state) => state.totalEntities);

  const [scannersList, setScannersList] = useState<ScannerDevice[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, changePage] = useState<number>(0);

  useEffect(() => {
    getScanners(page, SCANNERS_PAGE_SIZE).then((scanners) => {
      setScannersList(scanners);
    });
  }, [totalScanners, getScanners, page]);

  const onSubmit = async (value: any) => {
    createScanner(value);
    setIsModalOpen(false);
  };

  return (
    <Content className="ScannerDevicePage">
      <Title>Dispositivos: Scanners</Title>
      <Button type={"primary"} onClick={() => setIsModalOpen(true)}>
        <PlusOutlined /> Add new scanner
      </Button>
      <DeviceFormModal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        handleSubmit={onSubmit}
        device={null}
        actionText={"Añadir scanner"}
        type={DeviceType.scanner}
        isEdit={false}
      />
      <br />
      <br />
      <ScannerDevicesTable
        data={scannersList}
        pagination={{
          pageSize: SCANNERS_PAGE_SIZE,
          total: totalScanners,
          showSizeChanger: false,
        }}
        onChange={(pagination) => {
          getScanners(pagination.current! - 1, SCANNERS_PAGE_SIZE).then((scanners) => {
            setScannersList(scanners);
            changePage(pagination.current! - 1);
          });
        }}
      ></ScannerDevicesTable>
    </Content>
  );
};

export default ScannerDevicePage;
