import { ScannerDevice } from "../../models/devices/scanner-device";
import { BinDevice } from "../../models/devices/bin-device";
import { Button, Form, Input } from "antd";
import { DeviceType } from "../../models/devices/device-type";

interface DeviceFormProps {
  device: ScannerDevice | BinDevice | null;
  handleSubmit: any;
  buttonMessage: string;
  type: string;
  isEdit: boolean;
}

const DeviceForm = (props: DeviceFormProps) => {
  return (
    <Form
      name="device-form"
      layout="horizontal"
      initialValues={props.device ? props.device : {}}
      onFinish={props.handleSubmit}
      className={"DeviceForm"}
      scrollToFirstError
    >
      <Form.Item name="name" label="Name" rules={[{ required: true }]} hidden={props.isEdit}>
        <Input type="text" />
      </Form.Item>
      <Form.Item name={["data", "cpuSerial"]} label="CPU Serial">
        <Input type="text" />
      </Form.Item>
      <Form.Item name={["data", "cm4Model"]} label="CM4 Model">
        <Input type="text" />
      </Form.Item>
      <Form.Item name={["data", "simIccid"]} label="SIM ICCID">
        <Input type="text" />
      </Form.Item>
      <Form.Item name={["data", "embeddedHardwareVersion"]} label="Hardware Version">
        <Input type="text" />
      </Form.Item>
      <Form.Item name={["data", "embeddedSoftwareVersion"]} label="Software Version">
        <Input type="text" />
      </Form.Item>
      <Form.Item name={["data", "hostname"]} label="Hostname">
        <Input type="text" />
      </Form.Item>
      <Form.Item name={["data", "osUsername"]} label="OS Username">
        <Input type="text" />
      </Form.Item>
      <Form.Item name={["data", "osPassword"]} label="OS Password">
        <Input.Password type="text" />
      </Form.Item>
      <Form.Item name={["data", "wifiSsid"]} label="WIFI SSID">
        <Input type="text" />
      </Form.Item>
      <Form.Item name={["data", "wifiPassword"]} label="WIFI Password">
        <Input.Password type="text" />
      </Form.Item>
      <Form.Item name={["data", "comment"]} label="Comentario">
        <Input type="text" />
      </Form.Item>
      <Form.Item name={["data", "shopUuid"]} label="UUID del comercio" hidden={props.type !== DeviceType.scanner}>
        <Input type="text" />
      </Form.Item>
      <Button type="primary" htmlType="submit" className="LoginPost" block>
        {props.buttonMessage}
      </Button>
    </Form>
  );
};

export default DeviceForm;
