import { EditOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Space } from "antd";
import { Customer } from "../../models/users/customer";
import { useState } from "react";
import useCustomerStore from "../../stores/customers.store";

interface CustomerUpdateMessageProps {
  customer: Customer;
}
const CustomerEditButton = ({ customer }: CustomerUpdateMessageProps) => {
  const [open, setOpen] = useState(false);
  const updateCustomer = useCustomerStore((state) => state.update);

  const handleSubmit = async (values: Customer) => {
    values.uuid = customer.uuid;
    updateCustomer(values);
    setOpen(false);
  };

  return (
    <>
      <Space>
        <Button onClick={() => setOpen(true)}>
          <EditOutlined />
        </Button>
        <Modal title="Modificar cliente" open={open} onCancel={() => setOpen(false)} footer={null}>
          <Form<Customer> layout="vertical" initialValues={customer} autoComplete="off" onFinish={handleSubmit}>
            <Form.Item label="Nombre" name={["profile", "name"]} rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name={["profile", "email"]}
              rules={[
                { required: true },
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Button type={"primary"} block htmlType="submit">
              Modificar
            </Button>
          </Form>
        </Modal>
      </Space>
    </>
  );
};
export default CustomerEditButton;
