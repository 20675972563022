import { Button, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Content } from "antd/lib/layout/layout";
import useBinDeviceStore from "../../stores/bin-device.store";
import BinDeviceTable from "./BinDeviceTable";
import { PlusOutlined } from "@ant-design/icons";
import DeviceFormModal from "../../components/devices/DeviceFormModal";
import { DeviceType } from "../../models/devices/device-type";
import { BinDevice } from "../../models/devices/bin-device";
const { Title } = Typography;

const BINS_DEVICES_PAGE_SIZE = 10;

const BinDevicePage = () => {
  const createBin = useBinDeviceStore((state) => state.create);
  const getBins = useBinDeviceStore((state) => state.get);
  const totalBinsDevices = useBinDeviceStore((state) => state.totalEntities);

  const [binsDeviceList, setBinsDeviceList] = useState<BinDevice[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, changePage] = useState<number>(0);

  useEffect(() => {
    getBins(page, BINS_DEVICES_PAGE_SIZE).then((bins) => {
      setBinsDeviceList(bins);
    });
  }, [totalBinsDevices, getBins, page]);

  const onSubmit = async (value: any) => {
    createBin(value);
    setIsModalOpen(false);
  };

  return (
    <Content className="BinDevicePage">
      <Title>Dispositivos: Recipientes</Title>
      <Button type={"primary"} onClick={() => setIsModalOpen(true)}>
        <PlusOutlined />
        Añadir nuevo recipiente
      </Button>
      <DeviceFormModal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        handleSubmit={onSubmit}
        device={null}
        actionText={"Añadir recipiente"}
        type={DeviceType.bin}
        isEdit={false}
      />
      <br />
      <br />
      <BinDeviceTable
        data={binsDeviceList}
        pagination={{
          pageSize: BINS_DEVICES_PAGE_SIZE,
          total: totalBinsDevices,
          showSizeChanger: false,
        }}
        onChange={(pagination) => {
          getBins(pagination.current! - 1, BINS_DEVICES_PAGE_SIZE).then((bins) => {
            setBinsDeviceList(bins);
            changePage(pagination.current! - 1);
          });
        }}
      ></BinDeviceTable>
    </Content>
  );
};

export default BinDevicePage;
