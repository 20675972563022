import { Button, Modal, Space } from "antd";
import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import React from "react";
import useBinsStore from "../../stores/bin.store";

const { confirm } = Modal;

interface BinDeleteProps {
  uuid: string;
}

const BinDeleteButton = ({ uuid }: BinDeleteProps) => {
  const deleteBin = useBinsStore((state) => state.delete);

  const onClick = async () => {
    confirm({
      title: "Seguro que quieres borrar esta papelera?",
      icon: <ExclamationCircleFilled />,
      onOk: async function () {
        deleteBin(uuid);
      },
    });
  };

  return (
    <>
      <Space>
        <Button onClick={onClick}>
          <DeleteOutlined />
        </Button>
      </Space>
    </>
  );
};

export default BinDeleteButton;
