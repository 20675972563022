import { Button, Col, Layout } from "antd";
import { Header } from "antd/es/layout/layout";
import "./PrivatePageHeader.scss";
import React from "react";
import useAuthStore from "../../stores/auth.store";

const PrivatePageHeader = () => {
  const signOut = useAuthStore((state) => state.signOut);
  return (
    <Layout className="PrivatePageHeader">
      <Header style={{ display: "flex", alignItems: "center" }}>
        <Col flex={0}>
          <h1 className="PrivatePageHeader__title">PAKA</h1>
        </Col>
        <Col flex="auto">Fill Rest</Col>
        <Col flex={0}>
          <Button block onClick={signOut}>
            Sign Out
          </Button>
        </Col>
      </Header>
    </Layout>
  );
};

export default PrivatePageHeader;
