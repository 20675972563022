import { Modal } from "antd";
import { Market } from "../../models/market/market";
import MarketForm from "./MarketForm";

interface ModalProps {
  open: boolean;
  onCancel: any;
  handleSubmit: any;
  market: Market | null;
  isCreate: boolean;
}
const ModalFormMarketComponent = (props: ModalProps) => {
  const text = props.isCreate ? "Añadir mercado" : "Modificar mercado";

  return (
    <Modal title={text} open={props.open} onCancel={props.onCancel} footer={null}>
      <MarketForm market={props.market} handleSubmit={props.handleSubmit} buttonMessage={text} />
    </Modal>
  );
};

export default ModalFormMarketComponent;
