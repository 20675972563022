import { PaginationProps, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Customer } from "../../models/users/customer";
import CustomerDeleteButton from "./CustomerDeleteButton";
import CustomerEditButton from "./CustomerEditButton";

const columns: ColumnsType<Customer> = [
  {
    title: "UUID",
    dataIndex: ["uuid"],
  },
  {
    title: "Nombre",
    dataIndex: ["profile", "name"],
  },
  {
    title: "Email",
    dataIndex: ["profile", "email"],
  },
  {
    title: "Editar",
    render: (_, record) => (
      <Space size="large">
        <CustomerEditButton customer={record} />
      </Space>
    ),
  },
  {
    title: "Borrar",
    render: (_, record) => (
      <Space size="large">
        <CustomerDeleteButton uuid={record.uuid} />
      </Space>
    ),
  },
];

type CustomerTableProps = {
  data: readonly Customer[];
  pagination: PaginationProps;
  onChange: (pagination: PaginationProps) => void;
};
const CustomersTable = (props: CustomerTableProps) => (
  <Table
    columns={columns}
    dataSource={props.data}
    rowKey={(record) => record.uuid}
    pagination={props.pagination}
    onChange={props.onChange}
  />
);

export default CustomersTable;
