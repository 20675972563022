import React from "react";
import { Card, Col, Row, Switch } from "antd";
import { MqttUser } from "../../models/devices/mqtt-user.entity";

interface MqttSectionProps {
  mqttUser: MqttUser;
}

const MqttSection = (props: MqttSectionProps) => {
  return (
    <Card title={"MQTT"}>
      <Row>
        <Col flex={1}>MQTT User: {props.mqttUser.username}</Col>
        <Col flex={1}>
          <span>Enabled: </span>
          <Switch checked={props.mqttUser.enabled} disabled />
        </Col>
      </Row>
    </Card>
  );
};

export default MqttSection;
