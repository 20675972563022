import { SwapOutlined } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import React, { useState } from "react";
import usePackStore from "../../stores/pack.store";
import { QrScanner } from "@yudiel/react-qr-scanner";

interface PackEditProps {
  pack: string;
  box: string;
}

const PackReplaceButton = (props: PackEditProps) => {
  const updatePack = usePackStore((state) => state.update);

  const pack = usePackStore((state) => state.entities[props.pack!]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  function onResult(result: string) {
    let qrCompany = result.split(":")[0];
    let qrType = result.split(":")[2];
    let qrUuid = result.split(":")[4];

    if (qrCompany === "paka" && qrType === "e" && !pack.boxes.includes(qrUuid)) {
      pack.boxes = pack.boxes.filter((box) => box !== props.box);
      pack.boxes.push(qrUuid);
      updatePack(pack);
    }
  }

  return (
    <>
      <Space>
        <Button onClick={() => setIsModalOpen(true)}>
          <SwapOutlined />
        </Button>
        <Modal title="Editar paquete" open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={null}>
          <p>Escanea el identificador del nuevo envase</p>
          <QrScanner
            onDecode={(result) => onResult(result)}
            onError={(error) => console.log(error?.message)}
            scanDelay={100}
            constraints={{ facingMode: "environment" }}
          />
        </Modal>
      </Space>
    </>
  );
};

export default PackReplaceButton;
