import { ColumnsType } from "antd/es/table";
import { PaginationProps, Space, Table } from "antd";
import React from "react";
import { Bin } from "../../models/bin";
import BinDeleteButton from "./BinDeleteButton";
import BinEditButton from "./BinEditButton";
import BinEditDeviceButton from "./BinEditDeviceButton";

const columns: ColumnsType<Bin> = [
  {
    title: "UUID",
    dataIndex: "uuid",
    key: "uuid",
  },
  {
    title: "Nombre",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Dirección",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Latitud",
    dataIndex: "latitude",
    key: "latitude",
  },
  {
    title: "Longitud",
    dataIndex: "longitude",
    key: "longitude",
  },
  {
    title: "UUID Dispositivo",
    key: "deviceUuid",
    render: (_, record) => (
      <Space size="middle">
        {record.deviceUuid}
        <br />
        <BinEditDeviceButton bin={record} />
      </Space>
    ),
  },
  {
    title: "Editar",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <BinEditButton bin={record} />
      </Space>
    ),
  },
  {
    title: "Borrar",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <BinDeleteButton uuid={record.uuid} />
      </Space>
    ),
  },
];

type BinTableProps = {
  data: readonly Bin[];
  pagination: PaginationProps;
  onChange: (pagination: PaginationProps) => void;
};
const BinTable = (props: BinTableProps) => {
  return (
    <Table
      columns={columns}
      dataSource={props.data}
      rowKey={"uuid"}
      pagination={props.pagination}
      onChange={props.onChange}
    />
  );
};

export default BinTable;
