import { Flex } from "antd";
import CustomersTable from "./CustomersTable";
import useCustomerStore from "../../stores/customers.store";
import { useEffect, useState } from "react";
import Title from "antd/es/typography/Title";
import { Customer } from "../../models/users/customer";

const CUSTOMERS_PAGE_SIZE = 10;

const CustomerPage = () => {
  const getCustomers = useCustomerStore((state) => state.getByPagination);
  const totalCustomers = useCustomerStore((state) => state.totalEntities);

  const [customersList, setCustomersList] = useState<Customer[]>([]);
  const [page, changePage] = useState<number>(0);

  useEffect(() => {
    getCustomers(page, CUSTOMERS_PAGE_SIZE).then((customers) => {
      setCustomersList(customers);
    });
  }, [totalCustomers, getCustomers, page]);

  return (
    <Flex vertical>
      <Title>Clientes</Title>
      <br />
      <CustomersTable
        data={customersList}
        pagination={{
          pageSize: CUSTOMERS_PAGE_SIZE,
          total: totalCustomers,
          showSizeChanger: false,
        }}
        onChange={(pagination) => {
          getCustomers(pagination.current! - 1, CUSTOMERS_PAGE_SIZE).then((customers) => {
            setCustomersList(customers);
            changePage(pagination.current! - 1);
          });
        }}
      ></CustomersTable>
    </Flex>
  );
};

export default CustomerPage;
