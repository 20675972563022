import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";
import { Shop } from "../../models/users/shop";
import useShopsStore from "../../stores/shops.store";
import ShopFormModal from "../../components/users/shop/ShopFormModal";

const CreateShopButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const createShop = useShopsStore((state) => state.create);

  const handleSubmit = async (shop: Shop) => {
    createShop(shop);
    setIsModalOpen(false);
  };

  return (
    <div>
      <Button type="primary" onClick={() => setIsModalOpen(true)}>
        <PlusOutlined /> Añadir comercio
      </Button>

      <ShopFormModal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        handleSubmit={handleSubmit}
        shop={null}
        isCreate={true}
      />
    </div>
  );
};

export default CreateShopButton;
