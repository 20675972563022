import { create } from "zustand";
import { Box } from "../models/boxes/box";
import { boxApi } from "../api/box.api";
import { BoxState } from "../models/boxes/box-state";

type BoxStoreEntity = { [key: string]: Box };
interface BoxStore {
  entities: BoxStoreEntity;
  totalEntities: number;
  totalSoldEntities: number;
  create: () => void;
  get: (page: number, interval: number) => Promise<Box[]>;
  delete: (uuid: string) => void;
  update: (uuid: string, box: Box) => void;
  getSoldBoxes: (page: number, interval: number) => Promise<Box[]>;
  returnBox: (uuid: string, box: Box) => void;
}

export const useBoxStore = create<BoxStore>()((set, get) => ({
  entities: {},
  totalEntities: 0,
  totalSoldEntities: 0,
  get: async (page: number, interval: number) => {
    const data = await boxApi.getBoxesByPagination(page, interval);
    const currentBoxes = get().entities;
    const boxes = data.data;

    boxes.forEach((box) => {
      currentBoxes[box.uuid] = box;
    });

    set({ entities: currentBoxes, totalEntities: data.metadata.totalCount });
    return boxes;
  },
  getSoldBoxes: async (page: number, interval: number) => {
    const response = await boxApi.getSoldBoxesByPagination(page, interval);
    const currentBoxes = get().entities;

    const soldBoxes = response.data;

    const updatedSoldBoxes = soldBoxes.map((box) => ({
      ...box,
      stateData: {
        ...box.stateData,
        customerUuid: (box as any).owner,
      },
    }));

    soldBoxes.forEach((box) => {
      currentBoxes[box.uuid] = box;
    });

    set({ entities: currentBoxes, totalSoldEntities: updatedSoldBoxes.length });

    return updatedSoldBoxes;
  },
  create: async function (): Promise<void> {
    const createdBox = await boxApi.create();
    set((state) => {
      return {
        entities: { ...state.entities, ...{ [createdBox.uuid]: createdBox } },
        totalEntities: state.totalEntities + 1,
      };
    });
  },
  delete: async function (uuid: string) {
    const response = await boxApi.delete(uuid);
    if (response) {
      set((state) => {
        delete state.entities[uuid];
        return {
          entities: { ...state.entities },
          totalEntities: state.totalEntities - 1,
        };
      });
    }
  },
  returnBox: async function (uuid: string, box: Box) {
    const PAKA_UUID = "00000000-0000-0000-0000-000000000000";
    box.state = BoxState.InUsage;

    if (!box.stateData) {
      box.stateData = {
        operatorUuid: undefined,
        packUuid: undefined,
        shopUuid: undefined,
        customerUuid: undefined,
        binUuid: undefined,
      };
    }

    box.stateData.shopUuid = PAKA_UUID;

    const updatedBox = await boxApi.update(uuid, box);
    set((state) => {
      return {
        entities: { ...state.entities, ...{ [updatedBox.uuid]: updatedBox } },
        totalSoldEntities: state.totalSoldEntities + 1,
      };
    });
  },
  update: async function (uuid: string, box: Box) {
    const updateBox = await boxApi.update(uuid, box);
    set((state) => {
      return {
        entities: { ...state.entities, ...{ [updateBox.uuid]: updateBox } },
        totalEntities: state.totalEntities + 1,
      };
    });
  },
}));

export const selectBoxList = (state: BoxStore) => Object.values(state.entities);
export default useBoxStore;
