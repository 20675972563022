import { DeleteOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { useMarketStore } from "../../stores/market.store";

interface MarketDeleteMessageProps {
  uuid: string;
}

const MarketDeleteButton = ({ uuid }: MarketDeleteMessageProps) => {
  const deleteMarket = useMarketStore((state) => state.delete);

  const onClick = async () => {
    deleteMarket(uuid);
  };
  return (
    <>
      <Space>
        <Button onClick={onClick}>
          <DeleteOutlined />
        </Button>
      </Space>
    </>
  );
};

export default MarketDeleteButton;
