import useBoxStore from "../../stores/box.store";
import { EditOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, Space } from "antd";
import React, { useState } from "react";
import { Box } from "../../models/boxes/box";
import { BoxState } from "../../models/boxes/box-state";
import FormItem from "antd/es/form/FormItem";

interface BoxUpdateProps {
  box: Box;
}

const BoxUpdateButton = ({ box }: BoxUpdateProps) => {
  const updateBox = useBoxStore((state) => state.update);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedState, setSelectedState] = useState(box.state);

  const onChange = (value: string) => {
    setSelectedState(value as BoxState);
  };

  const handleSubmit = async (updatedbox: Box) => {
    box.state = selectedState;
    box.stateData = updatedbox.stateData;
    updateBox(box.uuid, box);
    setIsModalOpen(false);
  };

  const showInput = (name: string) => {
    switch (name) {
      case "operatorUuid":
        return !(
          selectedState === BoxState.Packed ||
          selectedState === BoxState.InWarehouse ||
          selectedState === BoxState.InShop
        );
      case "packUuid":
        return !(selectedState === BoxState.Packed || selectedState === BoxState.InShop);
      case "customerUuid":
        return !(selectedState === BoxState.InUsage || selectedState === BoxState.Collected);
      case "shopUuid":
        return !(selectedState === BoxState.InUsage);
      case "binUuid":
        return !(selectedState === BoxState.Collected);
      default:
        return true;
    }
  };

  return (
    <>
      <Space>
        <Button onClick={() => setIsModalOpen(true)}>
          <EditOutlined />
        </Button>
      </Space>
      <Modal title="Editar paquete" open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={null}>
        <Form<Box>
          layout="vertical"
          name="state-packed"
          onFinish={handleSubmit}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <FormItem label={"Estado actual"}>
            <Select
              defaultValue={box.state}
              style={{ width: "100%" }}
              onChange={onChange}
              options={Object.values(BoxState).map((state) => ({ label: state, value: state }))}
            />
          </FormItem>
          <FormItem
            name={["stateData", "operatorUuid"]}
            label={"Identificador del operador"}
            hidden={showInput("operatorUuid")}
          >
            <Input />
          </FormItem>
          <FormItem name={["stateData", "packUuid"]} label={"Identificador del pack"} hidden={showInput("packUuid")}>
            <Input />
          </FormItem>
          <FormItem
            name={["stateData", "customerUuid"]}
            label={"Identificador del cliente"}
            hidden={showInput("customerUuid")}
          >
            <Input />
          </FormItem>
          <FormItem
            name={["stateData", "shopUuid"]}
            label={"Identificador del comercio"}
            hidden={showInput("shopUuid")}
          >
            <Input />
          </FormItem>
          <FormItem
            name={["stateData", "binUuid"]}
            label={"Identificador de la papelera"}
            hidden={showInput("binUuid")}
          >
            <Input />
          </FormItem>
          <Button type="primary" htmlType="submit">
            Actualizar
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default BoxUpdateButton;
