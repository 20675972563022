import React, { useEffect, useState } from "react";
import { Button, Space } from "antd";
import BinTable from "./BinTable";
import { Bin } from "../../models/bin";
import { PlusOutlined } from "@ant-design/icons";
import ModalFormBinComponent from "../../components/BinFormModal";
import Title from "antd/es/typography/Title";
import useBinsStore from "../../stores/bin.store";

const BINS_PAGE_SIZE = 10;
const BinPage = () => {
  const getBins = useBinsStore((state) => state.get);
  const createBin = useBinsStore((state) => state.create);
  const totalBins = useBinsStore((state) => state.totalEntities);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [binsList, setBinsList] = useState<Bin[]>([]);
  const [page, changePage] = useState<number>(0);

  useEffect(() => {
    getBins(page, BINS_PAGE_SIZE).then((bins) => setBinsList(bins));
  }, [totalBins, getBins, page]);

  function handleCreate(bin: Bin) {
    createBin(bin);
    setIsModalOpen(false);
  }

  return (
    <div>
      <Title>Papeleras</Title>
      <Space>
        <Button type={"primary"} onClick={() => setIsModalOpen(true)}>
          <PlusOutlined /> Añadir papelera
        </Button>
        <ModalFormBinComponent
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          handleSubmit={handleCreate}
          bin={null}
          actionText={"Añadir papelera"}
        />
      </Space>
      <br />
      <br />
      <BinTable
        data={binsList}
        pagination={{
          pageSize: BINS_PAGE_SIZE,
          total: totalBins,
          showSizeChanger: false,
        }}
        onChange={(pagination) => {
          getBins(pagination.current! - 1, BINS_PAGE_SIZE).then((bins) => {
            setBinsList(bins);
            changePage(pagination.current! - 1);
          });
        }}
      ></BinTable>
    </div>
  );
};

export default BinPage;
