import { Button, Form, Input } from "antd";
import { Market } from "../../models/market/market";

interface FormMarketProps {
  market: Market | null;
  handleSubmit: (values: Market) => void;
  buttonMessage: string;
}

const MarketForm = ({ market, handleSubmit, buttonMessage }: FormMarketProps) => {
  return (
    <Form<Market> layout="vertical" initialValues={market ? market : {}} autoComplete="off" onFinish={handleSubmit}>
      <Form.Item label="Nombre" name={["name"]} rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item label="Dirección" name={["address"]} rules={[]}>
        <Input />
      </Form.Item>

      <Button type={"primary"} block htmlType="submit">
        {buttonMessage}
      </Button>
    </Form>
  );
};

export default MarketForm;
