import { apiV1 } from "./api-v1";
import { Pack } from "../models/boxes/pack";
import { BoxState } from "../models/boxes/box-state";
import useAuthStore from "../stores/auth.store";
import { PaginationResult } from "../models/pagination";

export const packApi = {
  endpoint: "/packs",
  async create(qrCode: string, boxes: string[]): Promise<Pack> {
    const response = await apiV1.post(this.endpoint, {
      qrCode: qrCode,
      boxes: boxes,
      state: BoxState.Packed,
      data: {
        operatorUuid: useAuthStore.getState().uuid,
      },
    });
    return response.data;
  },
  async getByPagination(pageIndex: number, pageSize: number): Promise<PaginationResult<Pack>> {
    let response = await apiV1.get(`${this.endpoint}?pageIndex=${pageIndex}&pageSize=${pageSize}`);
    return response.data;
  },
  async delete(uuid: string): Promise<boolean> {
    const response = await apiV1.delete(`${this.endpoint}/${uuid}`);
    return !!response.data;
  },
  async get(uuid: string): Promise<Pack> {
    const response = await apiV1.get(`${this.endpoint}/${uuid}`);
    return response.data;
  },
  async update(pack: Pack): Promise<Pack> {
    const response = await apiV1.put(`${this.endpoint}/${pack.uuid}`, {
      qrCode: pack.qrCode,
      boxes: pack.boxes,
      state: pack.state,
      data: {
        operatorUuid: useAuthStore.getState().uuid,
      },
    });
    return response.data;
  },
  async updateState(pack: Pack): Promise<Pack> {
    const response = await apiV1.put(`${this.endpoint}/${pack.uuid}/state`, {
      state: pack.state,
      data: pack.stateData,
    });
    return response.data;
  },
};
