import { FormOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import React, { useState } from "react";
import { Bin } from "../../models/bin";
import ModalFormBinComponent from "../../components/BinFormModal";
import useBinsStore from "../../stores/bin.store";

interface BinEditProps {
  bin: Bin;
}

const BinEditButton = ({ bin }: BinEditProps) => {
  const updateBin = useBinsStore((state) => state.update);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleEdit = async (values: Bin) => {
    values.uuid = bin.uuid;
    updateBin(values);
    setIsEditModalOpen(false);
  };

  return (
    <>
      <Space>
        <Button onClick={() => setIsEditModalOpen(true)}>
          <FormOutlined />
        </Button>
        <ModalFormBinComponent
          open={isEditModalOpen}
          onCancel={() => setIsEditModalOpen(false)}
          handleSubmit={handleEdit}
          bin={bin}
          actionText={"Editar papelera"}
        />
      </Space>
    </>
  );
};

export default BinEditButton;
