import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Market } from "../../models/market/market";
import { useMarketStore } from "../../stores/market.store";
import ModalFormMarketComponent from "../../components/market/MarketFormModal";

const CreateMarketButton = () => {
  const createMarket = useMarketStore((state) => state.create);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onFinish = async (market: Market) => {
    createMarket(market);
    setIsModalOpen(false);
  };
  return (
    <div>
      <Button type="primary" onClick={() => setIsModalOpen(true)}>
        <PlusOutlined /> Añadir mercado
      </Button>
      <ModalFormMarketComponent
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        handleSubmit={onFinish}
        market={null}
        isCreate={true}
      />
    </div>
  );
};

export default CreateMarketButton;
