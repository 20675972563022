import { Button, Modal, Space } from "antd";
import { ExclamationCircleFilled, RollbackOutlined } from "@ant-design/icons";
import React from "react";
import useBoxStore from "../../stores/box.store";
import { Box } from "../../models/boxes/box";

const { confirm } = Modal;

interface SoldBoxReturnButton {
  box: Box;
}

const BoxReturnButton = ({ box }: SoldBoxReturnButton) => {
  const returnBox = useBoxStore((state) => state.returnBox);

  const onClick = async () => {
    confirm({
      title: "Seguro que quieres devolver este envase?",
      icon: <ExclamationCircleFilled />,
      onOk: async function () {
        returnBox(box.uuid, box);
      },
    });
  };

  return (
    <>
      <Space>
        <Button onClick={onClick}>
          <RollbackOutlined />
        </Button>
      </Space>
    </>
  );
};

export default BoxReturnButton;
