import { Navigate, RouteObject } from "react-router-dom";
import SignInPage from "./pages/sign-in-page/SignInPage";
import PrivatePage from "./pages/private-page/PrivatePage";
import React from "react";
import OperatorPage from "./pages/operator-page/OperatorPage";
import SignUpForm from "./pages/sign-up-page/SignUpPage";
import ShopPage from "./pages/shop-page/ShopPage";
import CustomerPage from "./pages/customers-page/CustomerPage";
import PackagingPage from "./pages/packaging-pages/PackagingPage";
import ScannerDevicePage from "./pages/scanner-page/ScannerDevicePage";
import ScannerDetailPage from "./pages/scanner-details-page/ScannerDetailPage";
import BinDevicePage from "./pages/bin-device-page/BinDevicePage";
import BinDeviceDetailPage from "./pages/bin-device-details-page/BinDeviceDetailPage";
import BinPage from "./pages/bin-page/BinPage";
import PackPage from "./pages/pack-page/PackPage";
import BoxPage from "./pages/box-page/BoxPage";
import SoldBoxPage from "./pages/sold-box-page/SoldBoxPage";
import { MarketPage } from "./pages/market-page/MarketPage";

export const defaultPath = "/";
export const signInPath = "/sign-in";
export const signUpPath = "/sign-up";
export const privatePath = "/private";
export const operatorPath = "/private/operator";
export const shopPath = "/private/shop";
export const customerPath = "/private/customer";
export const binPath = "/private/bins";
export const packagingPath = "/private/packaging";

export const scannerDevicePath = "/private/device/scanners";
export const binDevicePath = "/private/device/bins";
export const generateScannerDeviceDetailPath = (uuid: string) => {
  return `/private/device/scanner/${uuid}`;
};
export const generateBinDeviceDetailPath = (uuid: string) => {
  return `/private/device/bin/${uuid}`;
};

export const packPath = "/private/packs";
export const boxPath = "/private/boxes";

export const soldBoxPath = "/private/sold-boxes";

export const marketPath = "/private/market";

const routes: RouteObject[] = [
  {
    path: defaultPath,
    children: [
      {
        path: signInPath,
        element: <SignInPage />,
      },
      {
        path: signUpPath,
        element: <SignUpForm />,
      },
      {
        path: privatePath,
        element: <PrivatePage />,
        children: [
          {
            path: operatorPath,
            element: <OperatorPage />,
          },
          {
            path: shopPath,
            element: <ShopPage />,
          },
          {
            path: marketPath,
            element: <MarketPage />,
          },
          {
            path: customerPath,
            element: <CustomerPage />,
          },
          {
            path: scannerDevicePath,
            element: <ScannerDevicePage />,
          },
          {
            path: "/private/device/scanner/:deviceUuid",
            element: <ScannerDetailPage />,
          },
          {
            path: binDevicePath,
            element: <BinDevicePage />,
          },
          {
            path: "/private/device/bin/:deviceUuid",
            element: <BinDeviceDetailPage />,
          },
          {
            path: binPath,
            element: <BinPage />,
          },
          {
            path: packPath,
            element: <PackPage />,
          },
          {
            path: boxPath,
            element: <BoxPage />,
          },
          {
            path: soldBoxPath,
            element: <SoldBoxPage />,
          },
          {
            path: packagingPath,
            element: <PackagingPage />,
          },
        ],
      },
      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
    ],
  },
];

export default routes;
